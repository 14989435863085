import { ListItem } from "Components/ListItem";
import { H3 } from "Components/Text";
import { AthleteData } from "Routes/Alumni";
import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ChevronSvg } from "Assets/Svgs/ChevronDown.svg";
import { WinnerDocument, YearWinnersDocument } from "types/types";

type ListSectionProps = {
  winnerDocs?:
    | QueryDocumentSnapshot<AthleteData>[]
    | QueryDocumentSnapshot<YearWinnersDocument>[];
  date: string;
  yearly?: boolean;
};

export const ListSection = ({ winnerDocs, date, yearly }: ListSectionProps) => {
  const [showMonthList, setShowMonthList] = useState<string[]>([]);

  const setMonthList = (val: string) => {
    if (showMonthList?.includes(val)) {
      let list = [...showMonthList];
      setShowMonthList(list.filter((d: string) => d !== date));
    } else {
      setShowMonthList((prev: string[]) => [...prev, val]);
    }
  };
  return (
    <Container>
      <H3 onClick={() => setMonthList(date)}>
        {yearly
          ? `Season 20${date} `
          : `Week ${parseInt(date.split(" ")[0])}, ${date.split(" ")[1]} `}
        <StyledSvg rotate={showMonthList?.includes(date)} />
      </H3>
      <div style={{ overflow: "hidden", width: "100%" }}>
        {showMonthList?.includes(date) && (
          <ListHeader>
            <div></div>
            <div>Athlete Name</div>
            <div>School</div>
            <div>Class.</div>
            <div>Position</div>
            <div>Nomination</div>
            <div>Grade</div>
          </ListHeader>
        )}
        {showMonthList?.includes(date) &&
          winnerDocs?.map((doc) => {
            let docs = doc?.data();
            return (
              <ListItem
                key={doc?.id}
                winnerId={doc?.id}
                docId={doc.id}
                athleteName={docs.athleteName}
                athleteSchool={docs.athleteSchool}
                athleteGrade={docs.athleteGrade}
                athleteClassification={docs.athleteClassification}
                athletePosition={docs.athletePosition}
                winnerView={true}
                winnerData={docs}
                // modalData={docs}
                yearly={yearly}
              />
            );
          })}
      </div>
      <span></span>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  overflow: hidden;
  ${H3} {
    margin-left: 60px;
    cursor: pointer;
    & > svg {
      margin: 5px 0 -5px;
      width: 18px;
    }
  }
  span {
    height: 1px;
    width: 90%;
    background-color: #999;
    margin: 10px 60px 0;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    ${H3} {
      margin-left: 20px;
    }
    span {
      margin: 10px 20px 0;
    }
  }
`;
const ListHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1.1fr 1.3fr 0.8fr 1fr 1fr 1fr;
  padding: 10px 0;
  & > div {
    font-size: 17px;
    font-weight: 600;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    & > div {
      font-size: 12px;
      font-weight: 600;
    }
  }
`;
const StyledSvg = styled(ChevronSvg)<{ rotate: boolean }>`
  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(180deg);
    `}
`;
const StyledListItem = styled(ListItem)<{ show?: boolean }>`
  transform: translateY(-110%);
  transition: 1s ease-in-out;
  ${({ show }) =>
    show &&
    css`
      transform: translateY(0) !important;
    `}
`;
