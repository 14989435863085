import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from 'react-gtm-module'
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID || "GTM-PMHLQZZ3"
};
TagManager.initialize(tagManagerArgs)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
