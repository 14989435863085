import { Routes as Router, Route, useNavigate } from "react-router-dom";
import { Home } from "./Home";
import { Nominate } from "./Nominate";
import { Alumni } from "./Alumni";
import { Admin } from "./Admin";
import { useUser } from "utils/useContext";
import { Login } from "./Login";
import { useEffect } from "react";

export const Routes = () => {
  return (
    <Router>
      <Route path="/nominate" element={<Nominate />} />
      <Route path="/alumni" element={<Alumni />} />
      <Route
        path="/admin"
        Component={() => (
          <AuthRedirect>
            <Admin />
          </AuthRedirect>
        )}
      />
      {/* <Route path="/admin" element={<Admin />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Redirect />} />
    </Router>
  );
};

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};

const AuthRedirect = ({ children }: { children: any }) => {
  const { user, claims } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user === null) {
      navigate("/login");
    }
    if (user && claims) {
      if (!claims.admin && !claims.faom) {
        navigate("/");
      }
    }
  }, [user, claims]);

  return user === undefined ? null : children;
};
