import {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getAuth,
  onAuthStateChanged,
  User,
  getIdTokenResult,
  ParsedToken,
} from "firebase/auth";

interface IContext {
  user?: User | null;
  claims?: ParsedToken;
}

export const UseUserContext = createContext<IContext>({});

export const UseUserProvider = (
  props: JSX.IntrinsicAttributes & Omit<ProviderProps<IContext>, "value">
) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [claims, setClaims] = useState<ParsedToken | undefined>(undefined);
  const auth = getAuth();

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdTokenResult(user).then((token) => {
          setClaims(token.claims);
          setUser(user);
          return;
        });
      } else {
        setUser(null);
      }
    });
  }, []);

  const value = { user, claims };
  return <UseUserContext.Provider {...props} value={value} />;
};

export const useUser = () => useContext(UseUserContext);
