import * as React from "react";
import { SVGProps } from "react";

export const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.6348 13.5498L30.2914 0H27.5292L17.4078 11.7651L9.32386 0H0L12.2245 17.7909L0 32H2.76239L13.4508 19.5756L21.9881 32H31.3119L18.6348 13.5498ZM3.75772 2.07949H8.00059L27.5305 30.0151H23.2876L3.75772 2.07949Z"
      fill="currentColor"
    />
  </svg>
);
export const TikTok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={42}
    viewBox="-1 -1 33 33"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      d="M21.227713 3c.4290139 3.68951971 2.4882807 5.88919107 6.0685969 6.12319866v4.14973464c-2.0748673.2028066-3.8923263-.4758154-6.0061949-1.7550569v7.7612518c0 9.8595199-10.7487487 12.9406198-15.07008891 5.8735905C3.443136 20.6051712 5.14359117 12.6255123 14.0514802 12.3057019v4.375942c-.678622.1092035-1.4040456.2808091-2.0670671.5070164-1.9812643.6708218-3.10450071 1.9266626-2.79249059 4.1419344.60061949 4.2433377 8.38527209 5.4991784 7.73785109-2.7924906V3.00780025h4.2979394V3Z"
    />
  </svg>
);
export const Instagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={42}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.4375,2 C15.9572992,2 18,4.04270083 18,6.5625 L18,13.4375 C18,15.9572992 15.9572992,18 13.4375,18 L6.5625,18 C4.04270083,18 2,15.9572992 2,13.4375 L2,6.5625 C2,4.04270083 4.04270083,2 6.5625,2 L13.4375,2 Z M13.4375,3.4375 L6.5625,3.4375 C4.8961236,3.4375 3.53437892,4.74178343 3.44244691,6.38516932 L3.4375,6.5625 L3.4375,13.4375 C3.4375,15.1038764 4.74178343,16.4656211 6.38516932,16.5575531 L6.5625,16.5625 L13.4375,16.5625 C15.1038764,16.5625 16.4656211,15.2582166 16.5575531,13.6148307 L16.5625,13.4375 L16.5625,6.5625 C16.5625,4.83661016 15.1633898,3.4375 13.4375,3.4375 Z M10,5.90625 C12.2609157,5.90625 14.09375,7.73908431 14.09375,10 C14.09375,12.2609157 12.2609157,14.09375 10,14.09375 C7.73908431,14.09375 5.90625,12.2609157 5.90625,10 C5.90625,7.73908431 7.73908431,5.90625 10,5.90625 Z M10,7.34375 C8.53299363,7.34375 7.34375,8.53299363 7.34375,10 C7.34375,11.4670064 8.53299363,12.65625 10,12.65625 C11.4670064,12.65625 12.65625,11.4670064 12.65625,10 C12.65625,8.53299363 11.4670064,7.34375 10,7.34375 Z M14.2578125,4.78125 C14.7885236,4.78125 15.21875,5.21147637 15.21875,5.7421875 C15.21875,6.27289863 14.7885236,6.703125 14.2578125,6.703125 C13.7271014,6.703125 13.296875,6.27289863 13.296875,5.7421875 C13.296875,5.21147637 13.7271014,4.78125 14.2578125,4.78125 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
export const Facebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 23.9861 5.85097 30.6053 13.5 31.8056V20.625H9.4375V16H13.5V12.475C13.5 8.465 15.8887 6.25 19.5434 6.25C21.294 6.25 23.125 6.5625 23.125 6.5625V10.5H21.1074C19.1198 10.5 18.5 11.7333 18.5 12.9987V16H22.9375L22.2281 20.625H18.5V31.8056C26.149 30.6053 32 23.9861 32 16Z"
      fill="currentColor"
    />
  </svg>
);
export const Youtube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.6277 7.07101C37.3489 7.52276 38.7041 8.85385 39.1641 10.5442C40 13.6078 40 20 40 20C40 20 40 26.392 39.1641 29.456C38.7041 31.1461 37.3489 32.4772 35.6277 32.9292C32.5084 33.75 20 33.75 20 33.75C20 33.75 7.49159 33.75 4.37227 32.9292C2.65136 32.4772 1.29591 31.1461 0.835909 29.456C0 26.392 0 20 0 20C0 20 0 13.6078 0.835909 10.5442C1.29591 8.85385 2.65136 7.52276 4.37227 7.07101C7.49159 6.25 20 6.25 20 6.25C20 6.25 32.5084 6.25 35.6277 7.07101ZM26.3636 20.0002L15.9091 25.8036V14.1964L26.3636 20.0002Z"
      fill="currentColor"
    />
  </svg>
);
