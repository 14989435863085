import { useEffect } from "react";
import styled from "styled-components";
import { Header } from "Components/Header";
import { H1, Body1, H2, H3 } from "Components/Text";
import athleteImg from "Assets/Images/NomForm.png";
import { RegistrationForm } from "Components/RegistrationForm/index";
import { Main } from "../Home";
import { Footer } from "Components/Footer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { slideLeft, fadeRight } from "Assets/Animate";

export const Nominate = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("animate");
    }
  }, [controls, inView]);

  return (
    <Main>
      <Header />
      <Container>
        <H1>Nomination Form</H1>
        <Content>
          <Legend>
            <Body1>
              This award is designed to recognize and celebrate the remarkable
              achievements of high school football athletes in classification 2A
              – 6A plus eligible private schools across Texas. To nominate a
              player for the Built Ford Tough Player of the Week, enter the
              information below and click on the Submit Nomination button.
              <br /> <br />
              Nominations will be collected each week ending at midnight on the
              Sunday following the game. Then, a prestigious board made up of
              sports media professionals will vote for the winner for each
              classification.
              <br /> <br />
              Please note: Participation in the recognition program is
              voluntary.
              <br /> <br />
              To have players’ photos and bio removed from this website, please
              contact Mark Yates at{" "}
              <a href="mailto:mark.yates@thefandomgroup.com">
                mark.yates@thefandomgroup.com
              </a>
              .
            </Body1>
            <motion.img
              ref={ref}
              src={athleteImg}
              variants={slideLeft}
              initial="initial"
              animate="animate"
              alt=""
            />
          </Legend>
          <FormWrapper>
            <RegistrationForm />
          </FormWrapper>
        </Content>
      </Container>
      <Footer />
    </Main>
  );
};

const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 100px 100px 50px;
  color: #112b4d;
  ${H1} {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid #cccccc;
  }
  img {
    width: 80%;
    object-fit: cover;
    margin: 30px auto;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
  padding: 30px 0;
  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: column;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
  }
`;
const Legend = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  img {
    width: 80%;
    object-fit: cover;
    margin: 30px auto;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    img {
      display: none;
    }
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    img {
      display: none;
    }
  }
`;
const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  & > ${H2} {
    margin-bottom: -10px;
    color: #112b4d;
  }
`;
