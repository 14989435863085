import React from "react";

export const TrophySvg = ({ fill = "#F2F2F2", textColor = "#999999" }) => {
  return (
    <svg
      width="70"
      height="67"
      viewBox="0 0 70 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56 7.217V0H14V7.217H0V17.5C0 26.3375 6.6115 33.593 15.134 34.762C17.584 41.965 23.8315 47.397 31.5 48.685V52.5C31.5 56.3675 28.3675 59.5 24.5 59.5H21V66.5H24.5H31.5H38.5H45.5H49V59.5H45.5C41.6325 59.5 38.5 56.3675 38.5 52.5V48.685C46.1685 47.397 52.416 41.965 54.866 34.762C63.3885 33.593 70 26.3375 70 17.5V7.217H56ZM7 17.5V14.217H14V27.398C9.926 25.956 7 22.064 7 17.5ZM63 17.5C63 22.064 60.074 25.956 56 27.4015V14.217H63V17.5Z"
        fill={fill}
      />
      <path
        d="M22.0698 13L20.5098 5.59998H22.1298L23.2098 11.34L24.4898 5.59998H26.5498L27.8398 11.42L28.9798 5.59998H30.5698L28.8198 13H26.7398L25.4798 7.55998L24.2198 13H22.0698Z"
        fill={textColor}
      />
      <path
        d="M32.7659 13C32.1459 13 31.5159 12.73 31.5159 11.75V5.59998H36.3759V6.99998H33.1159V8.52998H35.8759L35.7759 9.92998H33.1159V11.3C33.1159 11.54 33.2059 11.6 33.4259 11.6H36.4559V13H32.7659Z"
        fill={textColor}
      />
      <path
        d="M38.8695 13C38.2495 13 37.6195 12.73 37.6195 11.75V5.59998H42.4795V6.99998H39.2195V8.52998H41.9795L41.8795 9.92998H39.2195V11.3C39.2195 11.54 39.3095 11.6 39.5295 11.6H42.5595V13H38.8695Z"
        fill={textColor}
      />
      <path
        d="M43.753 13V5.59998H45.353V8.88998L47.953 5.59998H49.743L47.213 8.71998L49.743 13H47.883L46.113 9.86998L45.353 10.78V13H43.753Z"
        fill={textColor}
      />
    </svg>
  );
};
