import React from "react";
import { ThemeProvider } from "styled-components";
// import { transitions } from "./transitions";
import "./reset.css";
import { mediaQueries } from "./mediaQueries";
import GlobalStyle from "./GlobalStyle";

export const theme = {
  mediaQueries,
};

export type ThemeType = typeof theme;

export const Theme = ({
  children,
  ...props
}: {
  children?: React.ReactNode;
  theme?: ThemeType;
}) => {
  const theme_ = { ...theme, ...props.theme };
  return (
    <ThemeProvider {...props} theme={theme_}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  );
};
