export interface AppThemeType {
  mobile: string;
  tablet: string;
  maxTablet: string;
  desktop: string;
}

export const mediaQueries: AppThemeType = {
  mobile: "@media all and (max-width: 768px)",
  tablet: "@media all and (min-width: 769px) and (max-width: 1263px)",
  maxTablet: "@media all and (max-width: 1263px)",
  desktop: "@media all and (min-width: 769px)",
};
