import { AthleteData } from "Routes/Alumni";
import _ from "lodash";
import { NominationDocument } from "types/types"

export const reducer = (state: Partial<NominationDocument> & Partial<AthleteData>, payload:Partial<NominationDocument>  & Partial<AthleteData>) => {
  
  const keys = Object.keys(payload);
  if (keys.length === 0) {
    return {}
  }
  if (_.isEqual(state, payload)) {
    return state;
  }

  if (_.isArray(payload)) {
    return payload;
  } else {
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];

      if (!_.isEqual(state[key as keyof NominationDocument], payload[key as keyof NominationDocument])) {
        return _.extend({}, state, payload);
      }
    }
  }
  return state;
};
