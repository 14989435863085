import { H3, H4 } from "Components/Text";
import React from "react";
import styled, { css } from "styled-components";
import { TrophySvg } from "Assets/Svgs/TrophySvg";
import { motion } from "framer-motion";

type DateProps = {
  month?: string;
  athleteClass?: string;
  active?: boolean;
  onClick: () => void;
};

export const ClassChip = ({ athleteClass, active, onClick }: DateProps) => {
  return (
    <Container active={active} onClick={onClick}>
      <H3>{athleteClass}</H3>
    </Container>
  );
};
export const MonthChip = ({ month, active, onClick }: DateProps) => {
  return (
    <ContainerMonth
      as={motion.div}
      whileTap={{ scale: 0.9 }}
      whileHover={{ scale: 1.1 }}
      key={month}
      activeMonth={active}
      onClick={onClick}
    >
      <TrophySvg
        fill={active ? "#1101ef" : "#f2f2f2"}
        textColor={active ? "#ffffff" : "#999999"}
      />
      <H3>{parseInt(month || "")}</H3>
    </ContainerMonth>
  );
};
const Container = styled.div<{ active?: boolean }>`
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  background-color: #1101ef;
  color: #fff;
  border-radius: 50px;
  opacity: 1;
  cursor: pointer;
  ${({ active }) =>
    !active &&
    css`
      opacity: 0.6;
    `}
  ${H3} {
    border: 2px solid white;
    border-radius: 5px;
    padding: 3px;
    font-weight: 800;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const ContainerMonth = styled.div<{ activeMonth?: boolean }>`
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  // background-color: #1101ef;
  color: #999999;
  border-radius: 50px;
  opacity: 1;
  position: relative;
  cursor: pointer;
  svg {
    fill: #f2f2f2;
  }
  ${H3} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30%;
    margin: auto;
    font-weight: 800;
    /* border: 2px solid white; */
    border-radius: 5px;
    padding: 3px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  ${({ activeMonth }) =>
    activeMonth &&
    css`
      color: #fff;
      & > svg {
        fill: #1101ef !important;
      }
    `}
`;
const Month = styled.div``;
