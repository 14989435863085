import { Input } from "Components/RegistrationForm/Fields";
import { ReactComponent as SearchSvg } from "Assets/Svgs/search.svg";
import styled from "styled-components";
import { useState } from "react";
import { motion } from "framer-motion";

type SearchProps = {
  search: (v: string) => void;
};

export const SearchBar = ({ search }: SearchProps) => {
  const [input, setInput] = useState<string>();

  return (
    <Container
      whileHover={{
        boxShadow: "0px 0px 3px 3px #00000011",
      }}
    >
      <Input
        value={input}
        onChange={(v: string) => {
          search(v);
          setInput(v);
        }}
        placeholder={
          <>
            <SearchSvg style={{ marginRight: 7, marginTop: "-10px" }} /> Search
            Alumni
          </>
        }
      />
    </Container>
  );
};
const Container = styled(motion.div)`
  border-radius: 6px;
  width: 30%;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding-top: 15px;
    width: 100%;
  }
`;
