import { memo } from "react";
import { PhoneField } from "./Phone";
import { RadioField } from "./Radio";
import { Select } from "./Select";
import { FieldProps, TextField } from "./Text";

export const Field = memo(
  ({
    type,
    value = "",
    error,
    onChange,
    placeholder,
    fieldName,
    disabled,
    options,
  }: FieldProps) => {
    switch (type) {
      case "select":
        return (
          <Select
            error={error}
            value={value}
            onChange={onChange}
            fieldName={fieldName}
            options={options}
            placeholder={placeholder}
          />
        );
      case "phone":
        return (
          <PhoneField
            error={error}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        );
      case "radio":
        return (
          <RadioField
            error={error}
            value={value}
            onChange={onChange}
            fieldName={fieldName}
            options={options}
          />
        );
      case "text":
        return (
          <TextField
            error={error}
            value={value}
            onChange={onChange}
            fieldName={fieldName}
            placeholder={placeholder}
            disabled={disabled}
          />
        );
      default:
        return null;
    }
  }
);
