import styled, { css } from "styled-components";
import { FieldMessage } from "./FieldMessage";
import { FieldProps } from "./Field/Text";

export const Input = ({
  onChange,
  value,
  placeholder,
  fieldName,
  error,
  success,
  maxLength,
  disabled,
  type = "text",
}: FieldProps) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onChange(e.target.value);
  };

  return (
    <TextContainer>
      {fieldName === "nomineeOtherSports" ||
      fieldName === "athleteProfile" ||
      fieldName === "gameStats" ||
      fieldName === "gameComments" ||
      fieldName === "nomineeAwards" ? (
        <TextArea
          onChange={handleChange}
          value={value}
          error={!!error?.status}
        />
      ) : (
        <Field
          onChange={handleChange}
          value={value}
          error={!!error?.status}
          disabled={disabled}
          type={type}
          maxLength={maxLength}
        />
      )}
      <Placeholder
        textArea={
          fieldName === "nomineeOtherSports" ||
          fieldName === "athleteProfile" ||
          fieldName === "gameStats" ||
          fieldName === "gameComments" ||
          fieldName === "nomineeAwards"
        }
        htmlFor={fieldName}
      >
        {!value && placeholder}
        {success && <FieldMessage type={error?.status ? "error" : "success"} />}
      </Placeholder>
    </TextContainer>
  );
};

const primaryColor = "#6E6E6E";
const inputPadding = "13px 12px 12px";
const borderRadius = "6px";
const successBorderColor = "#008200";
const errorBackground = "#fff";
const errorBorderColor = "#E45C52";
const errorFieldColor = "#00095b";

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: ${borderRadius};
  position: relative;
`;

export const Placeholder = styled.label<{ textArea?: boolean }>`
  position: absolute;
  top: 39%;
  left: 12px;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  font-family: "F1Reg";
  color: #999999;
  ${({ textArea }) =>
    textArea &&
    css`
      top: 13%;
    `}
  svg {
    margin-bottom: -3px;
  }
`;

const Field = styled.input<{ error?: boolean; disabled?: boolean }>`
  background-color: transparent;
  border: 1px solid #cccccc;
  border-radius: ${borderRadius};
  outline: 0;
  height: 40px;
  font-size: 12px;
  font-family: "F1Reg";
  padding: ${inputPadding};
  position: relative;
  width: 100%;
  color: #333333;
  font-weight: 500;
  -webkit-appearance: none;
  z-index: 1;
  //margin-bottom: 15px;
  &:focus {
    // border: 1px solid #4580dd;
  }
  ${({ error }) =>
    error &&
    css`
      border: 1px solid #e45c52;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: #666666d9;
      background-color: #f2f5f7;
    `}
`;
const TextArea = styled.textarea<{ error?: boolean }>`
  background-color: transparent;
  border: 1px solid #cccccc;
  border-radius: ${borderRadius};
  outline: 0;
  font-size: 12px;
  font-family: "F1Reg";
  padding: ${inputPadding};
  position: relative;
  width: 100%;
  resize: none;
  min-height: 100px;
  color: #333333;
  font-weight: 500;
  -webkit-appearance: none;
  z-index: 1;
  padding-top: 9px;
  line-height: 140%;
  //margin-bottom: 15px;
  &:focus {
    // border: 1px solid #4580dd;
  }
  ${({ error }) =>
    error &&
    css`
      border: 1px solid #e45c52;
    `}
`;
