import React, { ReactNode, forwardRef } from "react";
import styled from "styled-components";

export type RadioProps = {
  onChange: (v: string) => void;
  title: string;
  children?: ReactNode;
  checked: boolean;
  ref?: React.ForwardedRef<HTMLDivElement>;
  error?: { status?: { error: boolean; message: string } };
};

export const RadioLabel = styled.label`
  color: #333333;
  cursor: pointer;
  display: flex;
  position: relative;
  padding: 0;
  margin: 0;
`;

const HiddenRadio = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const StyledRadio = styled.svg<{ checked?: boolean }>`
  height: 14px;
  width: 14px;
  margin: 0px 7px 0 0;

  #inner {
    transform: ${(props) => (props.checked ? "scale(1)" : "scale(0)")};
    transform-origin: center center;
    transition: all 0.3s ease;
  }
`;

export const RadioTitle = styled.span<{ checked?: boolean }>`
  display: block;
  font-size: 13px;
`;

export const RadioContent = styled.span`
  display: block;
  line-height: 18px;
`;

export const IconContainer = styled.div`
  margin-bottom: 4px;
`;

export const RadioButton = forwardRef<HTMLDivElement, RadioProps>(
  (props, ref) =>
    (({
      onChange,
      title,
      children,
      checked,
      error,
      //  ...props
    }) => {
      const handleChange = () => {
        return onChange(title);
      };
      return (
        <RadioLabel>
          <HiddenRadio
            checked={checked}
            onChange={handleChange}
            // {...props}
          />

          <StyledRadio checked={checked}>
            <g fill="none">
              {!!error?.status ? (
                <circle stroke="#e45c52" id="outer" cx="7" cy="7" r="6.5" />
              ) : (
                <circle stroke="#999999" id="outer" cx="7" cy="7" r="6.5" />
              )}
              <circle fill="#112B4D" id="inner" cx="7" cy="7" r="5" />
            </g>
          </StyledRadio>

          {/* {!!icon && <IconContainer>{icon}</IconContainer>} */}
          {!!title && <RadioTitle checked={checked}>{title}</RadioTitle>}
          <RadioContent>{children}</RadioContent>
        </RadioLabel>
      );
    })({ ...props, ref })
);
