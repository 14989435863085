import { firebaseApp } from "Components/Firebase";
import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  doc,
  getFirestore,
  query,
} from "firebase/firestore";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import {
  ConfigDocument,
  NomineeClassifications,
  WinnerDocument,
  YearWinnersDocument,
} from "types/types";
import { Header } from "Components/Header";
import { AthleteList, Main } from "../Home";
import { SearchBar } from "Components/SearchBar";
import { Body1, H1, H2, Subtitle1 } from "Components/Text";
import styled, { css } from "styled-components";
import { ClassChip, MonthChip } from "Components/DateChip";
import defaultImg from "Assets/Images/default.jpg";
import { Fragment, useEffect, useState } from "react";
import { ReactComponent as ChevronSvg } from "Assets/Svgs/ChevronDown.svg";
import { AthleteCard, OpenModal, Wrapper } from "Components/AthleteCard";
import { Footer } from "Components/Footer";
import { Modal } from "Components/Modal";
import { DateTime } from "luxon";
import { Dropdown } from "Components/RegistrationForm/Fields/Field/SelectField";
import { DropdownItem } from "Routes/Admin";

export type AthleteData = WinnerDocument & {
  featuredClass?: string;
  featuredWeek?: string;
  featuredMonth?: string;
  featuredYear?: string;
};

export const Alumni = () => {
  const db = getFirestore(firebaseApp);
  const [winnerDocs, winnerDocsLoading] = useCollection(
    query(collection(db, "winners") as CollectionReference<WinnerDocument>),
  );
  const [winnerByYearDocs, winnerByYearDocsLoading] = useCollection(
    query(
      collection(db, "yearWinners") as CollectionReference<YearWinnersDocument>,
    ),
  );
  const [site, siteLoading] = useDocument(
    doc(db, "config/site") as DocumentReference<ConfigDocument>,
  );

  const loading =
    winnerDocsLoading || winnerByYearDocsLoading || siteLoading;

  return (
    <Main>
      <Header />
      {loading ? null : winnerDocs && winnerByYearDocs && site ? (
        <AlumniWithData
          winnerDocs={winnerDocs}
          winnerByYearDocs={winnerByYearDocs}
          site={site}
        />
      ) : null}
      <Footer />
    </Main>
  );
};

export const AlumniWithData = ({
  winnerDocs,
  winnerByYearDocs,
  site,
}: {
  winnerDocs: QuerySnapshot<WinnerDocument>;
  winnerByYearDocs: QuerySnapshot<YearWinnersDocument>;
  site: DocumentSnapshot<ConfigDocument>;
}) => {
  const [athleteClass, setAthleteClass] = useState<string>("6A");
  const [displayYear, setDisplayYear] = useState<string>();
  const [displayWeek, setDisplayWeek] = useState<string>();
  const [openList, setOpenList] = useState(false);
  const [weekList, setWeekList] = useState<string[]>([]);
  const [yearList, setYearList] = useState<number[]>();
  const [noResult, setNoResult] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<AthleteData[] | null>();
  const [athleteData, setAthleteData] = useState<AthleteData[]>();
  const [filteredAthletes, setFilteredAthletes] = useState<AthleteData[]>();
  const [completeSeason, setCompleteSeason] = useState<string>();

  useEffect(() => {
    const season = site?.data()?.publishedYear;
    const activeSeasons = site?.data()?.activeSeasons || [];
    if (activeSeasons) {
      setYearList([...activeSeasons]);
      setDisplayYear(activeSeasons[0]?.toString());
    }
    setCompleteSeason(season?.toString());
  }, [site]);

  useEffect(() => {
    if (winnerDocs) {
      let docData = winnerDocs?.docs?.map((doc) => {
        const [featuredYear, featuredWeek, featuredClass] = doc?.id.split("-");
        return {
          id: doc?.id,
          ...doc?.data(),
          featuredClass,
          featuredWeek,
          featuredYear,
        };
      });
      setAthleteData(docData);
    }
  }, [winnerDocs]);

  useEffect(() => {
    const displayAthletes = athleteData?.filter(
      (athlete: AthleteData) =>
        athlete.featuredYear === displayYear?.slice(2, 4),
    );
    let weekList = makeList(displayAthletes || [], "featuredWeek");
    setWeekList(weekList.sort((a, b) => parseInt(a) - parseInt(b)));
    displayYear === "2012" ? setDisplayWeek("10") : setDisplayWeek("01");
  }, [displayYear, athleteData]);

  useEffect(() => {
    const athletes = [...(athleteData || [])];
    setFilteredAthletes(
      athletes.filter(
        (data) =>
          data.featuredWeek === displayWeek &&
          data.featuredYear === displayYear?.slice(2, 4),
      ),
    );
  }, [displayYear, displayWeek, athleteData]);

  const renderYearWinner = () => {
    const yearlyWinners = winnerByYearDocs?.docs;
    const [filteredWinner] = (yearlyWinners || []).filter(
      (doc: QueryDocumentSnapshot<YearWinnersDocument>) => {
        const [featuredYear, featuredClass] = doc?.id.split("-");
        return (
          featuredYear === displayYear?.slice(2, 4) &&
          (featuredClass === "PR" ? "Private" : featuredClass) === athleteClass
        );
      },
    );
    let winner = filteredWinner?.data();
    return (
      <CardWrapper>
        <Image>
          <img src={winner?.athletePhoto || defaultImg} alt="" />
        </Image>
        <Details>
          <Name>
            <H2>{winner?.athleteName}</H2>
          </Name>
          <Info>
            <Subtitle1>{winner?.athleteGrade}</Subtitle1>
            <Subtitle1>{winner?.athletePosition}</Subtitle1>
            <Subtitle1>{winner?.athleteSchool}</Subtitle1>
          </Info>
        </Details>
      </CardWrapper>
    );
  };

  const search = (searchValue: string) => {
    setNoResult(false);
    if (!searchValue) {
      setSearchData(null);
    }
    if (searchValue) {
      let result = athleteData?.filter((athlete: AthleteData) =>
        athlete?.athleteName?.toLowerCase().includes(searchValue.toLowerCase()),
      );
      if (!result?.length) {
        setNoResult(true);
      } else setSearchData(result as unknown as AthleteData[]);
    }
  };

  const makeList = (data: AthleteData[], field: "featuredWeek") => {
    let list = data.reduce((acc: string[], data) => {
      if (!acc.includes(data[field] || "")) {
        acc.push(data[field] || "");
      }
      return acc;
    }, []);
    return list;
  };

  return (
    <>
      <SearchContainer>
        <H1 onClick={() => setOpenList(!openList)}>
          {`${displayYear || "Loading"} Alumni`} <ChevronSvg />
          <Dropdown open={openList}>
            {yearList?.map((option) => {
              return (
                <DropdownItem
                  key={option}
                  selected={option === parseInt(displayYear || "")}
                  onClick={() => {
                    setDisplayYear(option.toString());
                    setOpenList(false);
                  }}
                >
                  <Fragment>Season {option}</Fragment>
                </DropdownItem>
              );
            })}
          </Dropdown>
        </H1>
        <SearchBar search={search} />
      </SearchContainer>

      {parseInt(completeSeason || "") >= parseInt(displayYear || "") &&
        displayYear !== "2012" &&
        displayYear !== "2018" &&
        displayYear !== "2022" && (
          <YearContainer>
            <H2>Player of the Year</H2>
            <ChipContainer>
              {NomineeClassifications.map((classification: string) => (
                <ClassChip
                  key={classification}
                  athleteClass={
                    classification === "Private" ? "PR" : classification
                  }
                  active={athleteClass === classification}
                  onClick={() => setAthleteClass(classification)}
                />
              ))}
            </ChipContainer>
            {renderYearWinner()}
          </YearContainer>
        )}

      <MonthContainer hasSearch={!!searchData}>
        <H2>
          {searchData || noResult ? "Search Results" : "Athlete of the Week"}
        </H2>
        {!searchData && !noResult && (
          <MonthChipContainer>
            {weekList?.map((week: string) => {
              return displayYear !== site?.data()?.currentSeason?.toString() ? (
                <MonthChip
                  month={week}
                  active={displayWeek === week}
                  onClick={() => setDisplayWeek(week)}
                />
              ) : (
                parseInt(week) <= (site?.data()?.currentWeek || 1) && (
                  <MonthChip
                    month={week}
                    active={displayWeek === week}
                    onClick={() => setDisplayWeek(week)}
                  />
                )
              );
            })}
          </MonthChipContainer>
        )}
        <AthleteList>
          {noResult ? (
            <div>No results found</div>
          ) : (
            (searchData || filteredAthletes)
              ?.sort(
                (a: AthleteData, b: AthleteData) =>
                  parseInt(b.athleteClassification || "0") -
                  parseInt(a.athleteClassification || "0"),
              )
              ?.filter((data: AthleteData) => {
                return !searchData?.length
                  ? data.featuredWeek === displayWeek &&
                      data.featuredYear === displayYear?.slice(2, 4)
                  : true;
              })
              ?.map((data: AthleteData) => (
                <AthleteCard
                  key={data.id}
                  src={data.athletePhoto || defaultImg}
                  name={data?.athleteName || ""}
                  grade={data?.athleteGrade || ""}
                  position={data?.athletePosition || ""}
                  school={data?.athleteSchool || ""}
                  category={data?.athleteClassification || ""}
                  details={data?.athleteProfile || ""}
                />
              ))
          )}
        </AthleteList>
      </MonthContainer>
    </>
  );
};
const YearContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  background-color: #000259;
  color: #fff;
  padding: 50px 100px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 50px 10px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px;
  }
`;
const MonthContainer = styled.div<{ hasSearch: boolean }>`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  color: #000259;
  max-width: 1220px;
  background-color: #fff;
  padding: 50px 0;
  ${({ hasSearch }) =>
    hasSearch &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
      gap: 50px;
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px 20px;
  }
`;
const SearchContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1220px;
  color: #112b4d;
  padding: 50px 0;
  border-bottom: 1px solid #ccc;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    padding: 35px 20px;
  }
  ${H1} {
    position: relative;
    cursor: pointer;
    ${Dropdown} {
      width: 300px;
    }
  }
`;
const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 30px 0 50px;
  gap: 35px;
  flex-wrap: wrap;
`;
const MonthChipContainer = styled.div<{ isTablet?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 30px 0 50px;
  gap: 25px;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
    flex-wrap: wrap;
  }
`;
const CardWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 900px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    width: 100%;
  }
`;
const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${Subtitle1} {
    font-size: 17px;
  }
`;
const Name = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
const Details = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Image = styled.div`
  width: 50%;
  display: flex;
  img {
    object-fit: cover;
    width: 100%;
    /* height: 500px;
    width: 300px; */
    object-position: top center;
  }
`;
