import React, { useState } from "react";
import styled from "styled-components";
// import ImageCrop from "./ImageCrop";
import ImageDropzone from "./ImageDropzone";
import { ButtonPrimary as Button } from "Components/Button";
import { Body2 } from "../../Text";
import { ProgressRing } from "../../ProgressRing/";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useMobileQuery } from "utils/useMobileQuery";

const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border: 1px solid #e6e9eb;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 6px;
  margin-top: 20px;
  position: relative;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 180px;
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    button {
      min-width: 120px;
    }
    button + button {
      margin-left: 16px;
    }
  }
`;

const base64ToFile = async (dataUrl: string, filename: string) => {
  const blob = await (await fetch(dataUrl)).blob();
  return new File([blob], filename, { type: blob.type });
};

export const ImageUpload = ({
  onCancel,
  onComplete = () => {},
  baseUrl = "athletes/",
  imgName,
  autosave,
  ...props
}: {
  onCancel: VoidFunction;
  onComplete: (v: string) => void;
  imgName: string;
  baseUrl?: string;
  autosave?: boolean;
}) => {
  const [image, setImage] = useState(null);
  const [croppedImg, setCroppedImg] = useState<Blob | null>(null);
  const [progress, setProgress] = useState<number>(0);

  const storage = getStorage();

  const handleUpload = (file: any) => {
    const metadata = {
      contentType: file?.type,
    };
    const storageRef = ref(storage, baseUrl + crypto.randomUUID());
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    if (file) {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(uploadProgress);
        },
        (error) => {
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            onComplete(downloadURL);
          });
        }
      );
    }
  };
  const isMobile = useMobileQuery();

  const runAutosave = async (img: any, imgName: string) => {
    if (img) {
      const file = await base64ToFile(img, imgName);
      handleUpload(file);
    }
  };

  return (
    <Wrapper {...props}>
      {progress ? (
        <ProgressRing
          progress={progress}
          size="large"
          style={{ marginTop: 24 }}
        />
      ) : (
        <>
          {!image ? (
            <DropzoneWrapper>
              <ImageDropzone
                onSelectImage={
                  isMobile || autosave
                    ? (img) => {
                        runAutosave(img, imgName);
                      }
                    : (img) => {
                        setImage(img);
                      }
                }
              />
              <DetailContainer>
                <Body2>Supports JPEG and PNG</Body2>
                <Body2>Maximum size: 2MB</Body2>
              </DetailContainer>
            </DropzoneWrapper>
          ) : (
            <div style={{ width: "100%" }}>
              <Body2 style={{ margin: "8px 0 6px 0" }}>
                Drag the crop area and handles to create your desired crop.
              </Body2>
            </div>
          )}
        </>
      )}
      {!isMobile && !autosave && !progress && (
        <ButtonsContainer>
          <Button onClick={onCancel} text>
            Cancel
          </Button>
          <Button
            disabled={!croppedImg}
            onClick={() => {
              if (croppedImg) {
                handleUpload(croppedImg);
              }
            }}
          >
            Save
          </Button>
        </ButtonsContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  align-items: center;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 30px;
  color: #474747;
  pointer-events: none;
`;
