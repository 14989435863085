import { NavLink } from "Components/NavLink";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

type NavProps = {
  open: boolean;
  homeScreen?: boolean;
};

export const Nav = ({ open, homeScreen }: NavProps) => {
  return (
    <Container open={open}>
      <motion.span
        whileHover={{
          y: -1,
        }}
      >
        <NavLink to="/" homeScreen={homeScreen}>
          Home
        </NavLink>
      </motion.span>
      <motion.span
        whileHover={{
          y: -1,
        }}
      >
        <NavLink to="/nominate" homeScreen={homeScreen}>
          Nominate
        </NavLink>
      </motion.span>
      <motion.span
        whileHover={{
          y: -1,
        }}
      >
        <NavLink to="/alumni" homeScreen={homeScreen}>
          Alumni
        </NavLink>
      </motion.span>
    </Container>
  );
};
const Container = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  z-index: 13;

  @media (max-width: 1020px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    align-items: stretch;
    transform: ${(props) =>
      props.open ? "translateX(0)" : "translateX(100%)"};
    top: -5%;
    right: 0;
    height: 120%;
    width: 100%;
    padding: 150px 70px 0 50px;
    transition: transform 0.3s ease-in-out;
    text-decoration: none;
    /* ${({ open }) =>
      !open &&
      css`
        display: none;
      `} */
  }
`;
