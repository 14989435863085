import { firebaseApp } from "Components/Firebase";
import {
  CollectionReference,
  collection,
  getFirestore,
  query,
  doc,
  setDoc,
  QuerySnapshot,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentReference,
  Timestamp,
  DocumentData,
} from "firebase/firestore";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { Header, UserAvatar as AddButton } from "Components/Header";
import styled, { css } from "styled-components";
import { H1, Body1, H2, H3, Subtitle1 } from "Components/Text";
import { Main } from "../Home";
import { Footer } from "Components/Footer";
import { Fragment, useEffect, useRef, useState } from "react";
import { AthleteData } from "Routes/Alumni";
import { DateTime } from "luxon";
import {
  NominationDocument,
  NominationStatus,
  ConfigDocument,
  WinnerDocument,
  YearWinnersDocument,
} from "types/types";
import { AthleteChip } from "Components/AthleteChip";
import incompleteImg from "Assets/Images/incompleteImg.png";
import { ListItem } from "Components/ListItem";
import { NomineeClassifications } from "types/types";
import { ReactComponent as AddSvg } from "Assets/Svgs/AddAlt.svg";
import { ReactComponent as ChevronSvg } from "Assets/Svgs/ChevronDown.svg";
import { Dropdown } from "Components/RegistrationForm/Fields/Field/SelectField";
import { WinnerModal } from "Components/WinnerModal";
import { ListSection } from "Components/ListSection";
import { getAuth } from "firebase/auth";
import { Modal } from "Components/Modal";
import { useUser } from "utils/useContext";
import { YearWinnerModal } from "Components/YearWinnerModal";
import { ButtonPrimary } from "Components/Button";

let update = {
  toFirestore: (data: WithFieldValue<AthleteData>) => ({ ...data }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<AthleteData>,
    options: SnapshotOptions,
  ) => {
    let data = snapshot.data(options);
    const [featuredYear, featuredWeek, featuredClass] =
      snapshot?.id?.split("-");
    return {
      ...data,
      featuredWeek,
      featuredYear,
      featuredClass,
    };
  },
};

const requiredWeekFields = [
  "athleteName",
  "athleteSchool",
  "athleteGrade",
  "athleteClassification",
  "athletePosition",
  "athletePhoto",
  "athleteProfile",
] as const;
type WeekFields = (typeof requiredWeekFields)[number];

const requiredYearFields = [
  "athleteName",
  "athleteSchool",
  "athleteGrade",
  "athleteClassification",
  "athletePosition",
  "athletePhoto",
] as const;
type YearFields = (typeof requiredYearFields)[number];

export const Admin = () => {
  const [currentWeek, setCurrentWeek] = useState<number | null>(null);
  const [upcomingWeek, setUpcomingWeek] = useState<number | null>(null);
  const [currentYear, setCurrentYear] = useState<number | null>(2024);
  const [activeTab, setActiveTab] = useState<string>("new");
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [selectArchivedIds, setSelectArchivedIds] = useState<string[]>([]);
  const [openWeekList, setOpenWeekList] = useState(false);
  const [yearList, setYearList] = useState<number[]>();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showMonthList, setShowMonthList] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openYearModal, setOpenYearModal] = useState(false);
  const [incompleteWeek, setIncompleteWeek] = useState<boolean>(false);
  const [incompleteYear, setIncompleteYear] = useState<boolean>(false);
  const [publishedYear, setPublishedYear] = useState<number>();
  const classRef = useRef<string | null>(null);
  const classArrayRef = useRef<string[]>([]);
  const dateRef = useRef<string | null>(null);

  const weekOptions = Array.from(Array(12).keys()).map((n) => n + 1);

  const db = getFirestore(firebaseApp);
  const ref = query(collection(db, "winners")).withConverter(
    update,
  ) as CollectionReference<AthleteData>;
  const [dataSnapshot, loading, error] = useCollection(ref);

  const [winnerDocsUpd] = useCollection(
    query(collection(db, "winners") as CollectionReference<AthleteData>),
  );
  let winnerDocs = winnerDocsUpd?.docs;
  const [winnerByYearDocs] = useCollection(
    query(
      collection(db, "yearWinners") as CollectionReference<YearWinnersDocument>,
    ),
  );
  let winnerYearDocs = winnerByYearDocs?.docs;
  const [nominationDocs] = useCollection(
    query(
      collection(db, "nominations") as CollectionReference<NominationDocument>,
    ),
  );

  const [config] = useDocument(
    doc(db, "config/site") as DocumentReference<ConfigDocument>,
  );

  useEffect(() => {
    const week = config?.data()?.currentWeek;
    const season = config?.data()?.publishedYear;
    const year = config?.data()?.currentSeason;
    const activeSeasons = config?.data()?.activeSeasons || [];
    if (week) {
      setCurrentWeek(week);
      if (week === 12) {
        setUpcomingWeek(1);
      } else {
        setUpcomingWeek(week + 1);
      }
    }
    if (year) {
      setCurrentYear(year);
    }
    if (season) {
      setPublishedYear(season);
    }
    if (activeSeasons) {
      setYearList(activeSeasons);
    }
  }, [config]);

  useEffect(() => {
    let complete = true;
    const filteredWinners =
      winnerDocs?.filter((doc) => {
        const [featuredYear, featuredWeek] = doc?.id.split("-");
        return (
          parseInt(featuredWeek || "") === upcomingWeek &&
          parseInt(featuredYear) + 2000 === currentYear
        );
      }) ?? [];
    if (filteredWinners.length) {
      filteredWinners.forEach((doc: QueryDocumentSnapshot<AthleteData>) => {
        if (!completeWeekCheck(doc.data())) return (complete = false);
      });
    }
    if (!complete || filteredWinners.length < 6) {
      setIncompleteWeek(true);
    } else if (complete && filteredWinners.length === 6) {
      setIncompleteWeek(false);
    }
  }, [upcomingWeek, currentYear, winnerDocs]);

  useEffect(() => {
    // year
    let complete = true;
    const filteredWinners =
      winnerYearDocs?.filter((doc) => {
        const [featuredYear, featuredWeek] = doc?.id.split("-");
        return parseInt(featuredYear) + 2000 === currentYear;
      }) ?? [];
    if (filteredWinners.length) {
      filteredWinners.forEach(
        (doc: QueryDocumentSnapshot<YearWinnersDocument>) => {
          if (!completeYearCheck(doc.data())) return (complete = false);
        },
      );
    }
    if (!complete || filteredWinners.length < 6) {
      setIncompleteYear(true);
    } else if (complete && filteredWinners.length === 6) {
      setIncompleteYear(false);
    }
  }, [currentYear, winnerYearDocs]);

  const completeWeekCheck = (athleteData: WinnerDocument) => {
    return requiredWeekFields.reduce((acc: boolean, field: WeekFields) => {
      if (
        !Object.keys(athleteData).includes(field) ||
        athleteData[field as keyof WinnerDocument] === ""
      ) {
        return (acc = false);
      }
      return acc;
    }, true);
  };
  const completeYearCheck = (athleteData: YearWinnersDocument) => {
    return requiredYearFields.reduce((acc: boolean, field: YearFields) => {
      if (
        !Object.keys(athleteData).includes(field) ||
        athleteData[field as keyof YearWinnersDocument] === ""
      ) {
        return (acc = false);
      }
      return acc;
    }, true);
  };

  const filterWeekWinners = (classification: string) => {
    let complete = true;
    const [filteredWinner] =
      winnerDocs?.filter((doc) => {
        const [featuredYear, featuredWeek, featuredClass] = doc?.id.split("-");
        return (
          parseInt(featuredWeek) === upcomingWeek &&
          parseInt(featuredYear) + 2000 === currentYear &&
          classification === doc.data().athleteClassification
        );
      }) ?? [];
    const winner = filteredWinner?.data();
    if (winner) {
      complete = completeWeekCheck(winner);
    }
    if (!winner) {
      return (
        <AddButtonWrapper
          key={classification}
          onClick={() =>
            openWinnerModal(
              classification,
              `${(currentYear || 1) - 2000}-${
                (upcomingWeek || 1)?.toString()?.length > 1
                  ? upcomingWeek
                  : `0${upcomingWeek}`
              }`,
            )
          }
        >
          <Subtitle1>{classification}</Subtitle1>
          <AddButton>
            <AddSvg />
          </AddButton>
        </AddButtonWrapper>
      );
    }

    return (
      <AthleteChip
        winnerId={filteredWinner?.id}
        winnerDoc={winner}
        key={filteredWinner?.id}
        src={winner.athletePhoto || incompleteImg}
        name={winner.athleteName || ""}
        grade={winner.athleteGrade || ""}
        category={winner.athleteClassification || ""}
        details={winner.athleteProfile || ""}
        upcomingStyle={true}
        completeProfile={complete}
        classificationList={classArrayRef.current}
      />
    );
  };

  const filterYearWinners = (
    classification: string,
    upcomingCheck?: boolean,
  ) => {
    let complete = true;

    const [filteredWinner] =
      winnerYearDocs?.filter((doc) => {
        const [featuredYear, featuredWeek, featuredClass] = doc?.id.split("-");
        return (
          parseInt(featuredYear) + 2000 ===
            (upcomingCheck ? currentYear : publishedYear) &&
          classification === doc.data().athleteClassification
        );
      }) ?? [];
    let winner = filteredWinner?.data();
    if (winner) {
      complete = completeYearCheck(winner);
    }

    if (!winner) {
      return (
        <AddButtonWrapper
          key={classification}
          onClick={() =>
            openYearWinnerModal(
              classification,
              currentYear?.toString()?.slice(2, 4) || "",
            )
          }
        >
          <Subtitle1>{classification}</Subtitle1>
          <AddButton>
            <AddSvg />
          </AddButton>
        </AddButtonWrapper>
      );
    }
    return (
      <AthleteChip
        winnerId={filteredWinner?.id}
        yearWinnerDoc={winner}
        key={filteredWinner?.id}
        src={winner.athletePhoto || incompleteImg}
        name={winner.athleteName || ""}
        grade={winner.athleteGrade || ""}
        category={winner.athleteClassification || ""}
        details={""}
        upcomingStyle={upcomingCheck}
        completeProfile={complete}
        classificationList={classArrayRef.current}
      />
    );
  };

  const updateCurrentWeek = async () => {
    if (upcomingWeek && !incompleteWeek) {
      const docRef = doc(db, "config", "site");
      if (upcomingWeek === 1 && currentYear) {
        const completeYearList = [...(yearList || [])];
        if (!completeYearList.includes(currentYear)) {
          completeYearList.unshift(currentYear);
        }
        await setDoc(
          docRef,
          {
            activeSeasons: completeYearList,
          },
          { merge: true },
        );
      }
      const updatedDate = upcomingWeek;
      await setDoc(
        doc(db, "config", "site"),
        {
          currentWeek: updatedDate,
        },
        { merge: true },
      );
    }
  };
  const publishCurrentYear = async () => {
    if (currentYear && !incompleteYear) {
      setUpcomingWeek(1);
      await setDoc(
        doc(db, "config", "site"),
        {
          publishedYear: currentYear,
          currentWeek: 12,
          currentSeason: currentYear + 1,
        },
        { merge: true },
      );
    }
  };

  const updateStatus = async (
    snap: QuerySnapshot<NominationDocument> | undefined,
    status: NominationStatus,
    archived?: boolean,
  ) => {
    snap?.docs.map((nominationDoc) => {
      if (
        (archived ? selectArchivedIds : selectIds)?.includes(nominationDoc?.id)
      ) {
        setDoc(
          nominationDoc.ref,
          {
            status: status,
          },
          { merge: true },
        );
      }
    });
    setSelectIds([]);
    setSelectArchivedIds([]);
  };

  const openWinnerModal = (classification: string, featuredWeek: string) => {
    setOpenModal(true);
    classRef.current = classification;
    dateRef.current = featuredWeek;
  };

  const openYearWinnerModal = (
    classification: string,
    featuredYear: string,
  ) => {
    setOpenYearModal(true);
    classRef.current = classification;
    dateRef.current = featuredYear;
  };

  const renderWeekWinnerList = (
    winnerDocs?: QueryDocumentSnapshot<AthleteData>[],
  ) => {
    let monthList = winnerDocs
      ?.reduce((arr: string[], doc: QueryDocumentSnapshot<AthleteData>) => {
        const [year, week] = doc?.id.split("-");
        const updatedDate = DateTime.fromFormat(year, "yy")
          .set({
            month: parseInt(week),
          })
          .toFormat("MM yyyy");
        const docWeek = `Week ${week}, 20${year}`;
        if (arr.indexOf(updatedDate) === -1) arr.push(updatedDate);

        return arr;
      }, [])
      .sort((a: string, b: string) => {
        return (
          DateTime.fromFormat(b, "MM yyyy").toMillis() -
          DateTime.fromFormat(a, "MM yyyy").toMillis()
        );
      });
    return monthList?.map((date: string) => {
      const winnersPerMonth = winnerDocs?.reduce(
        (
          arr: QueryDocumentSnapshot<AthleteData>[],
          winner: QueryDocumentSnapshot<AthleteData>,
        ) => {
          if (winner) {
            const [year, week] = winner?.id.split("-");
            date ===
              DateTime.fromFormat(year, "yy")
                .set({
                  month: parseInt(week),
                })
                .toFormat("MM yyyy") && arr.push(winner);
          }

          return arr;
        },
        [],
      );
      return {
        date,
        winnerDocs: winnersPerMonth,
        last: monthList?.[0] || "",
      };
    });
  };
  const renderYearWinnerList = (
    winnerDocs?: QueryDocumentSnapshot<YearWinnersDocument>[],
  ) => {
    let yearList = winnerDocs
      ?.reduce(
        (arr: string[], doc: QueryDocumentSnapshot<YearWinnersDocument>) => {
          const [year, week] = doc?.id.split("-");
          if (arr.indexOf(year) === -1) arr.push(year);
          return arr;
        },
        [],
      )
      .sort((a: string, b: string) => {
        return parseInt(b) - parseInt(a);
      });
    return yearList?.map((date: string) => {
      const winnersPerYear = winnerDocs?.reduce(
        (
          arr: QueryDocumentSnapshot<YearWinnersDocument>[],
          winner: QueryDocumentSnapshot<YearWinnersDocument>,
        ) => {
          if (winner) {
            const [year, week] = winner?.id.split("-");
            date === year && arr.push(winner);
          }
          return arr;
        },
        [],
      );
      return {
        date,
        winnerDocs: winnersPerYear,
        yearly: true,
      };
    });
  };

  return (
    <Main>
      <Header />
      <Container>
        <HeaderWrapper>
          <H1>Nominations</H1>
        </HeaderWrapper>
        <Content>
          {currentWeek === 12 ? (
            <WinnerContainer>
              <HeaderContainer>
                <H2>{publishedYear} Players of the Year</H2>
              </HeaderContainer>
              <Winner>
                {NomineeClassifications.map((classification: string) =>
                  filterYearWinners(classification),
                )}
              </Winner>
            </WinnerContainer>
          ) : (
            <WinnerContainer>
              <HeaderContainer>
                <H2>Week {currentWeek} Winners</H2>
                <Subtitle1>Current Week</Subtitle1>
              </HeaderContainer>
              <Winner>
                {winnerDocs
                  ?.sort(
                    (a, b) =>
                      parseInt(b.data().athleteClassification || "0") -
                      parseInt(a.data().athleteClassification || "0"),
                  )
                  ?.filter((doc) => {
                    const [featuredYear, featuredWeek] = doc?.id.split("-");
                    return (
                      parseInt(featuredWeek || "") === currentWeek &&
                      parseInt(featuredYear) + 2000 === currentYear
                    );
                  })
                  ?.map((doc) => {
                    let docData = doc.data();
                    return (
                      <AthleteChip
                        winnerId={doc.id}
                        winnerDoc={docData}
                        key={doc.id}
                        src={docData.athletePhoto || incompleteImg}
                        name={docData?.athleteName || ""}
                        grade={docData?.athleteGrade || ""}
                        position={docData?.athletePosition || ""}
                        school={docData?.athleteSchool || ""}
                        category={docData?.athleteClassification || ""}
                        details={docData?.athleteProfile || ""}
                        classificationList={classArrayRef.current}
                      />
                    );
                  })}
              </Winner>
            </WinnerContainer>
          )}

          {upcomingWeek === 12 ? (
            <WinnerContainer $lightMode>
              <HeaderContainer>
                <H2>{currentYear} Players of the Year</H2>
                <ActionContainer>
                  <Subtitle1
                    style={{
                      color: incompleteYear ? "#999" : "#112B4D",
                      textDecoration: "underline",
                      cursor: incompleteYear ? "auto" : "pointer",
                      zIndex: 20,
                    }}
                    onClick={() => publishCurrentYear()}
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                  >
                    Publish
                    {showTooltip && incompleteYear && (
                      <span>
                        Complete athletes profiles to publish this year{" "}
                      </span>
                    )}
                  </Subtitle1>
                  <StyledSubtitle1
                    $header
                    onClick={() => setOpenWeekList(!openWeekList)}
                  >
                    Select Time Period{" "}
                    <StyledChevronSvg $rotate={openWeekList} />
                    <Dropdown open={openWeekList}>
                      {weekOptions
                        .filter((week) =>
                          currentWeek === 12 ? true : week !== currentWeek,
                        )
                        .map((week) => {
                          return (
                            <DropdownItem
                              key={week}
                              selected={week === upcomingWeek}
                              onClick={() => {
                                setUpcomingWeek(week);
                                setOpenWeekList(false);
                              }}
                            >
                              <Fragment>
                                {week === 12
                                  ? `Year Winners, ${currentYear}`
                                  : `Week ${week}, ${currentYear}`}
                              </Fragment>
                            </DropdownItem>
                          );
                        })}
                    </Dropdown>
                  </StyledSubtitle1>
                </ActionContainer>
              </HeaderContainer>
              <Winner>
                {NomineeClassifications.map((classification: string) =>
                  filterYearWinners(classification, true),
                )}
              </Winner>
            </WinnerContainer>
          ) : (
            <WinnerContainer $lightMode>
              <HeaderContainer>
                <H2>Week {upcomingWeek} Winners</H2>
                <ActionContainer>
                  <Subtitle1
                    style={{
                      color: incompleteWeek ? "#999" : "#112B4D",
                      textDecoration: "underline",
                      cursor: incompleteWeek ? "auto" : "pointer",
                      zIndex: 20,
                    }}
                    onClick={() => updateCurrentWeek()}
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                  >
                    Set as Current Week
                    {showTooltip && incompleteWeek && (
                      <span>
                        Complete athletes profiles to set this month as current{" "}
                      </span>
                    )}
                  </Subtitle1>
                  <StyledSubtitle1
                    onClick={() => setOpenWeekList(!openWeekList)}
                  >
                    Select Time Period{" "}
                    <StyledChevronSvg $rotate={openWeekList} />
                    <Dropdown open={openWeekList}>
                      {weekOptions
                        .filter((week) =>
                          currentWeek === 12 ? true : week !== currentWeek,
                        )
                        .map((week) => {
                          return (
                            <DropdownItem
                              key={week}
                              selected={week === upcomingWeek}
                              onClick={() => {
                                setUpcomingWeek(week);
                                setOpenWeekList(false);
                              }}
                            >
                              <Fragment>
                                {week === 12
                                  ? `Year Winners, ${currentYear}`
                                  : `Week ${week}, ${currentYear}`}
                              </Fragment>
                            </DropdownItem>
                          );
                        })}
                    </Dropdown>
                  </StyledSubtitle1>
                </ActionContainer>
              </HeaderContainer>
              <Winner>
                {NomineeClassifications.map((classification: string) =>
                  filterWeekWinners(classification),
                )}
              </Winner>
            </WinnerContainer>
          )}

          <StatusContainer>
            <TabsContainer>
              <Tab
                $active={activeTab === "new"}
                onClick={() => {
                  setActiveTab("new");
                  setSelectIds([]);
                }}
              >
                New
              </Tab>
              <Tab
                $active={activeTab === "consideration"}
                onClick={() => {
                  setActiveTab("consideration");
                  setSelectIds([]);
                }}
              >
                In Consideration
              </Tab>
              <Tab
                $active={activeTab === "win"}
                onClick={() => {
                  setActiveTab("win");
                  setSelectIds([]);
                }}
              >
                Past Winners
              </Tab>
            </TabsContainer>
            <ListContainer>
              {(activeTab === "new" || activeTab === "consideration") && (
                <ListHeader>
                  <div></div>
                  <div>Athlete Name</div>
                  <div>School</div>
                  <div>Class.</div>
                  <div>Position</div>
                  <div>Nomination</div>
                  <div>Received</div>
                </ListHeader>
              )}
              <List>
                {(activeTab === "new" || activeTab === "consideration") &&
                  nominationDocs?.docs
                    ?.sort(
                      (
                        a: QueryDocumentSnapshot<
                          NominationDocument<Timestamp>,
                          DocumentData
                        >,
                        b: QueryDocumentSnapshot<
                          NominationDocument<Timestamp>,
                          DocumentData
                        >,
                      ) =>
                        (b.data().submittedAt?.seconds || 0) -
                        (a.data().submittedAt?.seconds || 0),
                    )
                    .map((doc) => {
                      const data = doc.data();
                      return (
                        data.status === activeTab && (
                          <ListItem
                            key={doc.id}
                            docId={doc.id}
                            athleteName={data.nomineeLastName}
                            athleteSchool={data.nomineeSchool}
                            athleteClassification={data.nomineeClassification}
                            athletePosition={data.nomineePosition}
                            setSelectIds={setSelectIds}
                            selectIds={selectIds}
                            modalData={data}
                            docData={nominationDocs}
                            submittedAt={data.submittedAt}
                          />
                        )
                      );
                    })}

                {activeTab === "win" && (
                  <>
                    <ButtonContainer>
                      <ButtonPrimary
                        inactive={!showMonthList}
                        onClick={() => setShowMonthList(true)}
                      >
                        Past Monthly Winners
                      </ButtonPrimary>
                      <ButtonPrimary
                        inactive={showMonthList}
                        onClick={() => setShowMonthList(false)}
                      >
                        Past Yearly Winners
                      </ButtonPrimary>
                    </ButtonContainer>
                    {showMonthList
                      ? renderWeekWinnerList(winnerDocs)?.map((w) => (
                          <ListSection {...w} />
                        ))
                      : renderYearWinnerList(winnerYearDocs)?.map((w) => (
                          <ListSection {...w} />
                        ))}
                  </>
                )}
              </List>

              {(activeTab === "new" || activeTab === "consideration") && (
                <ButtonWrapper>
                  {activeTab === "new" ? (
                    <>
                      <SelectButton
                        $disabled={!selectIds.length}
                        onClick={() =>
                          updateStatus(nominationDocs, "consideration")
                        }
                      >
                        Move {!!selectIds.length ? `(${selectIds.length})` : ""}{" "}
                        to In Consideration
                      </SelectButton>
                      <SelectButton
                        $disabled={!selectIds.length}
                        onClick={() => updateStatus(nominationDocs, "archived")}
                      >
                        Move {!!selectIds.length ? `(${selectIds.length})` : ""}{" "}
                        to Archive
                      </SelectButton>
                    </>
                  ) : (
                    <SelectButton
                      $disabled={!selectIds.length}
                      onClick={() => updateStatus(nominationDocs, "archived")}
                    >
                      Move {!!selectIds.length ? `(${selectIds.length})` : ""}{" "}
                      to Archive
                    </SelectButton>
                  )}
                </ButtonWrapper>
              )}
            </ListContainer>
          </StatusContainer>
          <ArchiveContainer>
            <H2
              style={{ color: "#112B4D" }}
              onClick={() => {
                setSelectArchivedIds([]);
                setShowArchived(!showArchived);
              }}
            >
              Archived <StyledChevronSvg $rotate={showArchived} />
            </H2>
            {showArchived && (
              <ListContainer archived>
                <ListHeader>
                  <div></div>
                  <div>Athlete Name</div>
                  <div>School</div>
                  <div>Class.</div>
                  <div>Position</div>
                  <div>Nomination</div>
                  <div>Received</div>
                </ListHeader>
                <List>
                  {nominationDocs?.docs
                    ?.sort(
                      (
                        a: QueryDocumentSnapshot<
                          NominationDocument<Timestamp>,
                          DocumentData
                        >,
                        b: QueryDocumentSnapshot<
                          NominationDocument<Timestamp>,
                          DocumentData
                        >,
                      ) =>
                        (b.data().submittedAt?.seconds || 0) -
                        (a.data().submittedAt?.seconds || 0),
                    )
                    .map((doc) => {
                      const data = doc.data();
                      return (
                        doc.data().status === "archived" && (
                          <ListItem
                            key={doc.id}
                            docId={doc.id}
                            athleteName={data.nomineeLastName}
                            athleteSchool={data.nomineeSchool}
                            athleteClassification={data.nomineeClassification}
                            athletePosition={data.nomineePosition}
                            setSelectIds={setSelectArchivedIds}
                            selectIds={selectArchivedIds}
                            docData={nominationDocs}
                            modalData={data}
                            submittedAt={data.submittedAt}
                          />
                        )
                      );
                    })}
                </List>
                <ButtonWrapper>
                  <SelectButton
                    $disabled={!selectArchivedIds.length}
                    onClick={() => updateStatus(nominationDocs, "new", true)}
                  >
                    Move{" "}
                    {!!selectArchivedIds.length
                      ? `(${selectArchivedIds.length})`
                      : ""}{" "}
                    to New
                  </SelectButton>
                  <SelectButton
                    $disabled={!selectArchivedIds.length}
                    onClick={() =>
                      updateStatus(nominationDocs, "consideration", true)
                    }
                  >
                    Move{" "}
                    {!!selectArchivedIds.length
                      ? `(${selectArchivedIds.length})`
                      : ""}{" "}
                    to In Consideration
                  </SelectButton>
                </ButtonWrapper>
              </ListContainer>
            )}
          </ArchiveContainer>
        </Content>
      </Container>
      {openModal && (
        <WinnerModal
          setIsOpen={setOpenModal}
          isOpen={openModal}
          classification={classRef.current}
          classificationList={classArrayRef.current}
          date={dateRef.current}
        />
      )}
      {openYearModal && (
        <YearWinnerModal
          setIsOpen={setOpenYearModal}
          isOpen={openYearModal}
          classification={classRef.current}
          classificationList={classArrayRef.current}
          date={dateRef.current}
        />
      )}
      <Footer />
    </Main>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 50px;
  color: #112b4d;
  ${H1} {
    width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 20px;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 30px 0;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;
const WinnerContainer = styled.div<{ $lightMode?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
  background-color: #00095b;
  border-radius: 10px;
  ${({ $lightMode }) =>
    $lightMode &&
    css`
      background-color: #f2f5f7;
      ${H2} {
        color: #112b4d;
      }
    `}
`;
const Winner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  border-radius: 10px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-wrap: wrap;
    gap: 30px;
  }
`;
const HeaderContainer = styled.div`
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 30px 30px;
`;
const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: flex-start;
  justify-content: flex-end;
  // padding-right: 20px;
  cursor: pointer;
  ${Subtitle1} {
    position: relative;
    & > span {
      position: absolute;
      top: 108%;
      right: 0;
      font-size: 12px;
      background-color: #ffffff;
      box-shadow: 2px 2px 20px 8px #a6a6a66c;
      padding: 7px 20px 7px 10px;
      color: #333;
      width: 210px;
      border-radius: 6px;
      font-weight: 100;
    }
  }
  svg {
    width: 15px;
    // height: 2px;
    margin: 7px 0 -10px 3px;
    position: absolute;
    bottom: 40%;
  }
`;
const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const ListContainer = styled.div<{ archived?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 10px 10px 25px;
  border-radius: 0 0 10px 10px;
  background-color: #f2f5f7;
  ${({ archived }) =>
    archived &&
    css`
      background-color: #fff;
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 5px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 5px;
  }
`;
const ListHeader = styled.div`
  width: 100%;
  display: grid;
  /* grid-template-columns: 0.3fr 1.1fr 1.3fr 0.8fr 1fr 1fr; */
  grid-template-columns: 60px 1.1fr 1.3fr 0.8fr 1fr 1fr 1fr;
  padding: 10px;
  & > div {
    font-size: 17px;
    font-weight: 600;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    & > div {
      font-size: 12px;
      font-weight: 600;
    }
  }
`;
const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const TabsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
`;
const Tab = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 30px;
  color: #999999;
  ${({ $active }) =>
    $active &&
    css`
      background-color: #f2f5f7;
      color: #112b4d;
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 18px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 18px;
  }
`;
const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  margin: 15px 0 45px;
  cursor: pointer;
`;
const SelectButton = styled.div<{ $disabled?: boolean }>`
  font-size: 18px;
  text-decoration: underline;
  padding: 10px;
  margin-top: 15px;
  color: #112b4d;
  font-weight: 700;
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: #999999;
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 12px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 14px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 20px;
`;
const ArchiveContainer = styled.div<{ upcoming?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
  color: #112b4d;
  ${H2} {
    width: 100%;
    padding: 0 0 20px 10px;
    border-bottom: 1px solid #000;
    svg {
      margin: 3px 0 -3px 0;
    }
  }
  ${({ upcoming }) => upcoming && css``}
`;
const StyledChevronSvg = styled(ChevronSvg)<{ $rotate: boolean }>`
  cursor: pointer;
  transition: transform 0.4s ease;
  ${({ $rotate }) =>
    $rotate &&
    css`
      transform: rotate(180deg);
    `}
`;
const StyledSubtitle1 = styled(Subtitle1)<{ $header?: boolean }>`
  color: #112b4d;
  width: 170px;
  text-align: right;
  padding-right: 20px;
  /* margin-left: -50px; */
`;
export const DropdownItem = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-color: #dedede;
  border-bottom-width: 1px;
  box-sizing: border-box;
  padding: 12px 10px;
  color: black;
  cursor: pointer;
  line-height: 18px;
  position: relative;
  font-size: 13px;
  font-weight: 300;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  ${({ selected }) =>
    selected &&
    css`
      color: #999;
      pointer-events: none;
      cursor: none;
    `}
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px 30px 60px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 10px;
    flex-direction: column;
    padding: 20px;
  }
`;
