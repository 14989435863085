import { motion } from "framer-motion";
import { socials } from "./socials";
import { SVGProps, useEffect, useState } from "react";
import styled from "styled-components";

export const SocialFeed = () => {
  const [service, setService] = useState("facebook");
  const feedMap = {
    facebook: "a69fb5366889b2546c2586bbee61059569bf45d7",
    twitter: "89db9eef13f01be84431d4e677c1b31e0982d1f3",
    instagram: "688f5c47ff808e6a44e329913b351d403d0d1ff8",
    tiktok: "350a4f3e45802e64682be644a0c849aaa1afdb88",
  };
  //@ts-ignore
  let feed = feedMap[service];

  useEffect(() => {
    if (feed) {
      (function (d, s, id) {
        var js;
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        //@ts-ignore
        js.src = "https://embedsocial.com/cdn/ht.js";
        d.getElementsByTagName("head")[0].appendChild(js);
      })(window.document, "script", "EmbedSocialHashtagScript");
      return () => {
        window.document.getElementById("EmbedSocialHashtagScript")?.remove();
      };
    }
  }, [feed]);

  return (
    <Container>
      <Options>
        {socials.map(
          ({
            key,
            Image,
          }: {
            key: string;
            Image: (props: SVGProps<SVGSVGElement>) => JSX.Element;
          }) => (
            <Social
              as={motion.div}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.2 }}
              key={key}
              style={{ borderColor: key === service ? "#1700F4" : "#fff" }}
              onClick={() => setService(key)}
            >
              <Image
                style={{ color: key === service ? "#1700F4" : "#020C29" }}
              />
            </Social>
          ),
        )}
      </Options>
      <div className="embedsocial-hashtag" data-ref={feed}></div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  width: 40%;
  margin: 30px auto;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

const Social = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background: #fff;
  margin: 5px;
  color: #020c29;
  border-bottom: 3px;
  border-style: solid;
  &:hover {
  }
`;
