import styled from "styled-components";
import { RadioButton } from "./RadioButton";
import { FieldProps } from "./Text";

export const RadioField = ({ onChange, value, options, error }: FieldProps) => {
  const handleRadio = (value: boolean | string | undefined) => {
    onChange(value);
  };

  return (
    <RadioContainer>
      {options?.map(({ id, title }) => {
        return (
          <InlineRadio
            key={id}
            checked={value === title}
            onChange={() => handleRadio(title)}
            title={title || ""}
            error={error}
          >
            <StyledLabel>{title}</StyledLabel>
          </InlineRadio>
        );
      })}
    </RadioContainer>
  );
};

const StyledLabel = styled.p`
  color: #ffffff;
  margin: 5px 0;
  font-size: 12px;
`;

const InlineRadio = styled(RadioButton)`
  margin-top: 0;
  margin-bottom: 0;
  #outer {
    stroke: #fff;
    fill: #fff;
  }
  #inner {
    fill: #102b4e;
  }
`;
const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
`;
