import { DateTime } from "luxon";
// import { Button } from "melodies-source/Button";
import { Dropdown as DropdownBase } from "../Dropdown";
import { SvgCalendar } from "../../Assets/Svgs/Calendar";
// import { SvgClearCircle } from "melodies-source/Svgs/ClearCircle";
// import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import { SvgLeft } from "../../Assets/Svgs/Left";
import { SvgRight } from "../../Assets/Svgs/Right";
import { Body1, Caption, Selected } from "../Text";
// import {
//   HTMLInput,
//   TextInput as TextInputBase,
// } from "melodies-source/TextInput";
// import { colors } from "melodies-source/Theme/colors";
// import { useClickOutside } from "melodies-source/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Input } from "Components/RegistrationForm/Fields/Input";
import { FieldMessage } from "Components/RegistrationForm/Fields";
import { useClickOutside } from "utils/useClickOutside";

// const TextInput = styled(TextInputBase)`
//   input {
//     font-family: "Poppins", sans-serif;
//     font-weight: 500;
//     pointer-events: none;
//     box-shadow: none;

//     &:hover {
//       cursor: pointer;
//     }
//   }
// `;

const CalendarIcon = styled(SvgCalendar)`
  color: #666666;
`;

const Dropdown = styled(DropdownBase)<{ dateOnly?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 8px auto 0;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.14);
  padding: 20px 20px 12px;
  border: none;
  background-color: var(--component-background-color);
  ${({ dateOnly }) =>
    dateOnly &&
    css`
      width: unset;
    `}
`;

const ActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
`;

export const ActionIcon = styled.div`
  display: flex;
  border-radius: 100%;
  flex-shrink: 0;
  padding: 5px;

  :hover {
    cursor: pointer;
    background: var(--hover-background-color);
  }
`;

const MonthDateWrapper = styled.div`
  margin: 1px;
`;

const DayOfWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  margin-top: 16px;
  ${Caption} {
    color: #666666;
    font-weight: 500;
  }
`;

// const RightIcon = styled.svg`
//   color: ${({ theme }) => theme.colors.black60};
//   cursor: pointer;

//   &:hover {
//     color: ${({ theme }) => theme.colors.black80};
//   }
// `;

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const weekHeaderUI = (
  <DayOfWeek>
    {days.map((day, index) => (
      <Caption style={{ textAlign: "center" }} key={index}>
        {day}
      </Caption>
    ))}
  </DayOfWeek>
);

type Props = {
  value: DateTime | null;
  label?: string;
  onChange: (v: DateTime) => void;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
  minDate?: DateTime;
  hasError?:
    | {
        status?: {
          error: boolean;
          message: string;
        };
      }
    | undefined;
  helperText?: string;
  isRangePicker?: boolean;
  style?: any;
  fieldName: string;
  renderInput?: React.ReactNode;
  disabled?: boolean;
  placeholder?: string;
  timeZone?: string;
  type?: "dateTime" | "dateOnly";
};

interface InputValueProps {
  date?: DateTime;
  hours: string;
  minutes: string;
  meridiem: string;
}

export const Datepicker = ({
  label,
  value,
  fieldName,
  placeholder,
  onChange,
  onClose,
  onOpen,
  hasError,
  helperText,
  minDate,
  renderInput,
  disabled,
  timeZone,
  type = "dateOnly",
  ...props
}: Props) => {
  const [refDate, setRefDate] = useState<DateTime>(value || DateTime.now());
  const [timeState, setTimeState] = useState<InputValueProps>({
    hours: "12",
    minutes: "00",
    meridiem: "AM",
  });
  const [isPickerOpen, setIsPickerOpenState] = useState(false);
  const isAM = timeState.meridiem === "AM";

  useEffect(() => {
    if (timeZone) {
      setRefDate((d) => d?.setZone(timeZone));
    }
    if (value) {
      const [hours, minutes, meridiem] = value?.toFormat("h:mm:a")?.split(":");
      setTimeState({ hours, minutes, meridiem, date: value });
    }
  }, [timeZone]);

  const minDateTime = useMemo(
    () => minDate?.setZone(timeZone),
    [timeZone, minDate],
  );

  const setIsPickerOpen = (v: boolean) => {
    setIsPickerOpenState(v);
    onClose && !v && onClose();
    onOpen && v && onOpen();
  };

  const monthFirstDay = refDate.startOf("month");
  const monthLastDay = refDate.endOf("month");

  const monthDates = [];
  for (let i = 0; i < monthFirstDay.weekday; i++) monthDates.push(null);
  for (let d = 0; d < (refDate?.daysInMonth || 30); d++)
    monthDates.push(monthFirstDay.plus({ days: d }));

  const gotoNextMonth = () => setRefDate(monthLastDay.plus({ days: 1 }));
  const gotoPreviousMonth = () => setRefDate(monthFirstDay.minus({ days: 1 }));
  const monthHeaderUI = (
    <ActionWrap>
      <ActionIcon onClick={gotoPreviousMonth}>
        <SvgLeft />
      </ActionIcon>
      <Selected>
        {refDate.monthLong} {refDate.year}
      </Selected>
      <ActionIcon onClick={gotoNextMonth}>
        <SvgRight />
      </ActionIcon>
    </ActionWrap>
  );

  const valueUI = (
    <>
      <span onClick={() => !disabled && setIsPickerOpen(true)}>
        {renderInput || (
          <>
            <Input
              type="text"
              fieldName="gameDate"
              // label={label}
              placeholder={placeholder || "Please select..."}
              error={hasError}
              // helperText={helperText}
              value={
                value
                  ? type === "dateTime"
                    ? value?.toFormat("ff (ZZZZ)")
                    : value?.toLocaleString()
                  : ""
              }
              onChange={() => {}}
              //  leftIcon={<CalendarIcon style={{ color: "#666666" }} />}
              // rightIcon={
              //   value ? (
              //     <RightIcon as={SvgClearCircle}
              //     //onClick={() => onChange(null)}
              //     />
              //   ) : (
              //     <RightIcon as={SvgDropdown} />
              //   )
              // }
            />
            <FieldMessage
              fieldName={fieldName}
              active={!!value || !!hasError?.status}
              type={!!hasError?.status ? "error" : "success"}
            />
          </>
        )}
      </span>
    </>
  );

  const handleTimeChange = (dState: DateTime, tState: InputValueProps) => {
    if (!tState.hours || !tState.minutes) {
      return;
    }
    let hours = parseInt(tState.hours);
    if (tState.meridiem !== "AM" && hours !== 12) {
      hours = hours + 12;
    }
    if (tState.meridiem === "AM" && hours === 12) {
      hours = 0;
    }
    dState = dState.set({
      hour: hours,
      minute: parseInt(tState.minutes || "0"),
    });
    onChange(dState);
    if (type === "dateOnly") {
      setIsPickerOpen(false);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef.current, () => setIsPickerOpen(false));

  return (
    <Wrapper {...props} ref={containerRef}>
      {valueUI}
      <Dropdown
        placement="bottom-start"
        isOpen={isPickerOpen}
        onClose={() => setIsPickerOpen(false)}
        dateOnly={type === "dateOnly"}
      >
        <div>
          {monthHeaderUI}
          {weekHeaderUI}
          <DayOfMonth>
            {monthDates.map((date, index) => (
              <MonthDateWrapper key={index}>
                {date && (
                  <MonthDate
                    {...{
                      date,
                      value,
                      minDate: minDateTime,
                      onChange: () => handleTimeChange(date, timeState),
                    }}
                  />
                )}
              </MonthDateWrapper>
            ))}
          </DayOfMonth>
        </div>
        {type === "dateTime" && (
          <>
            {/* <TimePicker
              {...{
                setTimeState: (nextTimeState) => {
                  setTimeState(nextTimeState);
                  handleTimeChange(value, nextTimeState);
                },
                timeState,
                value,
                refDate,
              }}
            /> */}
            {/* <DoneButton
              variant="tertiary"
              onClick={() => setIsPickerOpen(false)}
            >
              Done
            </DoneButton> */}
          </>
        )}
      </Dropdown>
    </Wrapper>
  );
};

const DayOfMonth = styled.div`
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(7, 1fr);
`;

// const DoneButton = styled(Button)`
//   align-self: center;
//   width: auto;
//   border-radius: 13px;
//   height: 26px;
//   padding: 3px 16px;
//   font-size: 14px;
//   line-height: 20px;
//   font-weight: 500;
//   color: var(--text-color) !important;
//   border: 1px solid var(--text-color);

//   &:hover {
//     background-color: var(--hover-background-color);
//   }

//   &:focus {
//     background-color: ${({ theme }) => theme.colors.gray2};
//   }
// `;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #666666;
  & > div > div:first-child {
    pointer-events: none;
  }
`;

type MonthDateProps = {
  date: DateTime;
  onChange: VoidFunction;
} & Pick<Props, "value" | "onChange" | "minDate">;
const MonthDate = ({ value, date, minDate, onChange }: MonthDateProps) => {
  const isSelected = date.toISODate() === value?.toISODate();
  const isToday = date.toISODate() === DateTime.local().toISODate();
  const isPast = minDate ? date < minDate.startOf("day") : false;

  const onSelect = () => {
    if (isPast) return;
    onChange();
  };

  return (
    <MonthDateContainer onClick={onSelect} {...{ isSelected, isToday, isPast }}>
      {date.day}
    </MonthDateContainer>
  );
};

type DayContainerProps = {
  isPast?: boolean;
  isSelected?: boolean;
  isToday?: boolean;
};

const MonthDateContainer = styled(Body1)<DayContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 100%;
  height: 36px;
  width: 36px;
  font-weight: 400;
  color: var(--text-color);

  ${(p) =>
    p.isPast &&
    css`
      cursor: default;
      color: var(--disabled-color);
    `}
  ${(p) =>
    p.isToday &&
    css`
      color: #112b4d;
      font-weight: 600;
    `};
  ${(p) =>
    p.isSelected &&
    css`
      font-weight: 600;
      background-color: #dddbdb;
      color: #112b4d !important;
    `};
  ${(p) =>
    !p.isSelected &&
    !p.isPast &&
    css`
      :hover {
        background-color: var(--hover-background-color);
      }
    `}
`;

// const TimePicker = ({ setTimeState, timeState, value, refDate }) => {
//   const handleHours = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const valueString = e.target.value;
//     const value = parseInt(valueString);
//     if (valueString === "" || (/^[0-9\b]+$/.test(valueString) && value <= 12)) {
//       if (valueString.length === 2 && value < 1) {
//         return;
//       } else {
//         setTimeState({
//           ...timeState,
//           hours: valueString,
//         });
//       }
//       if (value > 1) {
//         const nextField = document.getElementById("minutes");
//         nextField && nextField.focus();
//       }
//     }
//   };

// const handleMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
//   const valueString = e.target.value;
//   const value = parseInt(valueString);
//   if (valueString === "" || (/^[0-9\b]+$/.test(valueString) && value <= 59)) {
//     if (valueString.length === 1 && value > 5) {
//       setTimeState({
//         ...timeState,
//         minutes: `0${valueString}`,
//       });
//     } else {
//       setTimeState({
//         ...timeState,
//         minutes: valueString,
//       });
//     }
//   }
// };

//   const handleMinutesBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = e.target.value;
//     if (value.length === 1) {
//       setTimeState({
//         ...timeState,
//         minutes: `0${value}`,
//       });
//     }
//   };
//   return (
//     <TimeWrapper>
//       <Selected>
//         Time {refDate && <span>{refDate.toFormat("(ZZZZ)")}</span>}
//       </Selected>
//       {/* <TimeContainer>
//         <InputContainer>
//           <TimeInput
//             type="tel"
//             maxLength={2}
//             placeholder="00"
//             onChange={handleHours}
//             value={timeState.hours}
//             disabled={!value}
//             onFocus={(e) => e.target.select()}
//           />
//           <Selected style={{ ...(!value && { color: colors.black20 }) }}>
//             :
//           </Selected>
//           <TimeInput
//             type="tel"
//             min="0"
//             max="59"
//             placeholder="00"
//             onChange={handleMinutes}
//             onBlur={handleMinutesBlur}
//             value={timeState.minutes}
//             maxLength={2}
//             disabled={!value}
//             onFocus={(e) => e.target.select()}
//             id="minutes"
//           />
//         </InputContainer>
//         <InputContainer>
//           <LeftButton
//             active={timeState.meridiem === "AM"}
//             variant={timeState.meridiem === "AM" ? "primary" : "secondary"}
//             disabled={!value}
//             onClick={() =>
//               setTimeState({
//                 ...timeState,
//                 meridiem: "AM",
//               })
//             }
//           >
//             AM
//           </LeftButton>
//           <RightButton
//             active={timeState.meridiem === "PM"}
//             variant={timeState.meridiem === "PM" ? "primary" : "secondary"}
//             disabled={!value}
//             onClick={() =>
//               setTimeState({
//                 ...timeState,
//                 meridiem: "PM",
//               })
//             }
//           >
//             PM
//           </RightButton>
//         </InputContainer>
//       </TimeContainer> */}
//     </TimeWrapper>
//   );
// };

const TimeWrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.gray4};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #ffffff;

  & > ${Selected} {
    background-color: var(--component-background-color);
    padding: 0 16px;
    display: flex;
    align-items: center;
    margin-top: -11px;
    color: var(--text-color);

    & > span {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

const TimeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  font-family: "Poppins", sans-serif;
  margin: 12px 0 16px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${Selected} {
    margin: 0 4px;
  }
`;

// const TimeInput = styled(HTMLInput)`
//   width: 55px;
//   text-align: center;
//   font-family: "Poppins", sans-serif;
//   font-weight: 500;
//   ${(p) =>
//     p.disabled &&
//     css`
//       border-color: ${({ theme }) => theme.colors.gray3};
//       color: ${({ theme }) => theme.colors.black20};
//     `};
// `;

// const LeftButton = styled(Button)<{ active?: boolean }>`
//   border-radius: 6px 0 0 6px;
//   width: 54px;
//   font-weight: 500;

//   &:hover,
//   &:focus {
//     background-color: var(--hover-background-color);
//     border-color: var(--text-color);
//     box-shadow: none;
//   }
//   ${({ active }) =>
//     active &&
//     css`
//       pointer-events: none;
//       &:hover,
//       &:focus {
//         background-color: ${({ theme }) => theme.colors.navy};
//         border-color: ${({ theme }) => theme.colors.navy};
//         box-shadow: none;
//       }
//     `}
//   ${(p) =>
//     p.disabled &&
//     css`
//       background-color: var(--component-background-color);
//       border-color: ${({ theme }) => theme.colors.gray3};
//       color: ${({ theme }) => theme.colors.black20};
//       pointer-events: none;
//     `};
// `;

// const RightButton = styled(LeftButton)`
//   border-radius: 0 6px 6px 0;
//   ${(p) =>
//     p.disabled &&
//     css`
//       border-left: none;
//     `};
// `;
