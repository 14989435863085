import React from "react";
import { useDropzone } from "react-dropzone";
import { happyCloudSvg, sadCloudSvg } from "./svgs";
import { ReactComponent as ImgPlaceholder } from "Assets/Svgs/ImgPlaceholder.svg";
import { H4 } from "Components/Text";
import styled from "styled-components";

const contentOptions = {
  inactive: {
    message: "Drop your image here, or",
    icon: (
      <ImgPlaceholder width="80px" height="80px" style={{ color: "#333" }} />
    ),
  },
  active: {
    valid: {
      message: "Drop It!",
      icon: happyCloudSvg,
    },
    invalid: {
      message: "Are you sure this is an image?",
      icon: sadCloudSvg,
    },
  },
};

type Props = {
  onSelectImage: (img: any) => void;
};
const ImageDropzone = ({ onSelectImage }: Props) => {
  const dzProps = useDropzone({
    accept: { "image/*": [] },
    onDrop: ([file]) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => onSelectImage(reader.result);
      }
    },
  });
  const { getRootProps, getInputProps, isDragActive, isDragAccept } = dzProps;

  const content = !isDragActive
    ? contentOptions.inactive
    : contentOptions.active[isDragAccept ? "valid" : "invalid"];

  return (
    <Wrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <div style={{ margin: "-35px auto 10px auto" }}>{content.icon}</div>
      {!isDragActive && (
        <H4>
          {content.message} <span>browse</span>
        </H4>
      )}
    </Wrapper>
  );
};

export default ImageDropzone;

const Wrapper = styled.div`
  color: #3f3f3f;
  background-color: #f2f5f7;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 6px;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 240px;
  cursor: pointer;
  ${H4} {
    text-align: center;
    span {
      display: inline;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
