import Joi from "@hapi/joi";
import { AthleteData } from "Routes/Alumni";
import _ from "lodash";
import { NominationDocument } from "types/types";

export const handleErrors = (
  fields: string[],
  formData: Partial<NominationDocument> & Partial<AthleteData>,
) => {
  let dynamicSchema: { [key: string]: Joi.AnySchema } = {};
  fields.forEach((field: string) => {
    switch (field) {
      case "nomineeFirstName":
        return (dynamicSchema[field] = Joi.string().required());
      case "nomineeLastName":
        return (dynamicSchema[field] = Joi.string().required());
      case "nomineeGrade":
        return (dynamicSchema[field] = Joi.string().required());
      case "nomineePosition":
        return (dynamicSchema[field] = Joi.string().required());
      case "gameDate":
        return (dynamicSchema[field] = Joi.required());
      case "gameAgainst":
        return (dynamicSchema[field] = Joi.string().required());
      case "gameStats":
        return (dynamicSchema[field] = Joi.string().required());
      case "gameComments":
        return (dynamicSchema[field] = Joi.string().required());
      case "nomineeSchool":
        return (dynamicSchema[field] = Joi.string().required());
      case "coachName":
        return (dynamicSchema[field] = Joi.string().required());
      case "nomineeClassification":
        return (dynamicSchema[field] = Joi.string().required());
      case "nominatorFirstName":
        return (dynamicSchema[field] = Joi.string().required());
      case "nominatorLastName":
        return (dynamicSchema[field] = Joi.string().required());
      case "nominatorEmail":
        return (dynamicSchema[field] = Joi.string()
          .email({ tlds: false })
          .required());
      case "athleteName":
        return (dynamicSchema[field] = Joi.string().required());
      case "athleteClassification":
        return (dynamicSchema[field] = Joi.string().required());
      default:
        break;
    }
  });

  let combinedSchema = Joi.object({
    ...dynamicSchema,
  });
  let { error } = combinedSchema.validate(formData, {
    abortEarly: false,
    allowUnknown: true,
  });
  let errorObject: {
    [key: string | number]: { error: boolean; message: string };
  } = {};
  _.get(error, "details", []).map(({ path, message }) => {
    let labeledMsg = "";
    switch (path[0]) {
      case "nominatorEmail":
        labeledMsg = "Please enter a valid email";
        break;
      case "nominatorPhone":
        labeledMsg = "Please enter a valid phone number";
        break;
      default:
        break;
    }
    return (errorObject[path[0]] = path[1]
      ? {
          ...errorObject[path[0]],
          [path[1]]: {
            error: true,
            message: labeledMsg ? labeledMsg : `This is a required field`,
          },
        }
      : {
          error: true,
          message: labeledMsg ? labeledMsg : `This is a required field`,
        });
  });
  return Object.keys(errorObject).length > 0 ? errorObject : null;
};
