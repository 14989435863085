import { Input, FieldMessage } from "../../Fields";
import { getFormattedPhoneNumber } from "../PhoneMask";
import { FieldProps } from "./Text";

export const PhoneField = ({
  onChange,
  value,
  placeholder,
  error,
  ...props
}: FieldProps) => {
  const handleText = (value: string) => {
    const formattedNumber = value.length ? getFormattedPhoneNumber(value) : "";
    onChange(formattedNumber);
  };

  return (
    <div style={{ position: "relative" }}>
      <Input
        type="tel"
        placeholder={placeholder ?? "Phone Number"}
        maxLength={16}
        onChange={(value: string) => handleText(value)}
        value={value}
        error={error}
        {...props}
      />
      <FieldMessage
        active={!!value || !!error?.status}
        type={error?.status ? "error" : "success"}
        message={error?.status?.message}
      />
    </div>
  );
};
