import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "Assets/Svgs/check-mark.svg";
import { RadioProps } from "Components/RegistrationForm/Fields/Field/RadioButton";

type CheckboxProps = Omit<RadioProps, "onChange" | "checked"> & {
  onChange: (v: boolean) => void;
  checked: boolean;
};

export const Checkbox = ({ checked, onChange, title }: CheckboxProps) => {
  return (
    <StyledCheckbox checked={checked} onClick={() => onChange(!checked)}>
      <Icon checked={checked} />
    </StyledCheckbox>
  );
};
const Icon = styled(CheckIcon)<{ checked: boolean }>`
  visibility: ${(props) => (props.checked ? "visible" : "hidden")};
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.checked ? "#ffffff" : "#ffffff")};
  border: 1px solid #333;
  border-radius: 2px;
  width: 16px;
  height: 16px;

  input[disabled] + & {
    background: #dcdcdc;
    border-color: #dcdcdc;
  }

  ${Icon} {
    width: 18px;
    height: 18px;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};

    path {
      fill: #000;
    }
  }
`;
