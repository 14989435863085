import styled from "styled-components";
import { Body2 } from "Components/Text";
import { DateTime } from "luxon";

export const Footer = () => {
  let year = DateTime.now().year || 2024;

  return (
    <Container>
      <Body2>© Copyright {year} - Player of the Week</Body2>
      <Links>
        <Body2>
          <a
            href="https://www.ford.com/help/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Ford Privacy Policy
          </a>
        </Body2>
        |{" "}
        <Body2>
          <a
            href="https://www.max.live/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            MAX Privacy Policy
          </a>
        </Body2>
        |{" "}
        <CookieButton
          onClick={() => {
            if ((window as any).Cookiebot) {
              (window as any).Cookiebot.renew();
            }
          }}
        >
          <Body2>Cookie Preferences</Body2>
        </CookieButton>
      </Links>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  padding: 30px 100px;
  align-items: center;
  background-color: #020c29;
  color: #fff;
  justify-content: space-between;
  margin-top: auto;
  gap: 40px;
  white-space: nowrap;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    padding: 30px;
    gap: 25px;
  }
`;
const Links = styled.div`
  display: flex;
  align-items: baseline;
  color: #fff;
  gap: 10px;
  justify-content: space-between;
  a {
    text-decoration: none;
    color: #fff;
  }
  ${Body2} {
    white-space: nowrap;
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  display: inline;
  color: #fff;
  cursor: pointer;
  padding: 0;
`;
