import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

export const NomineeClassifications = [
  "6A",
  "5A",
  "4A",
  "3A",
  "2A",
  "Private",
] as const;
type NomineeClassification = (typeof NomineeClassifications)[number];

export const NominationStatuses = [
  "new",
  "archived",
  "consideration",
  "winner",
] as const;
export type NominationStatus = (typeof NominationStatuses)[number];

export const NomineeGrades = [
  "Freshman",
  "Sophomore",
  "Junior",
  "Senior",
] as const;
type NomineeGrade = (typeof NomineeGrades)[number];

export const NomineePositions = [
  "Quarterback",
  "Running Back",
  "Wide Receiver",
  "Running Back/Middle Linebacker",
  "Running Back/Outside Linebacker",
  "Offensive Line",
  "Defensive Line",
  "Wide Receiver/Free Safety",
  "Defensive End",
  "Safety",
  "Quarterback/Defensive End",
  "Defensive End/Wide Receiver",
  "Linebacker",
  "Running Back/Defensive Back",
  "Fullback",
  "Quarterback/Linebacker",
  "Quarterback/Kicker",
] as const;
type NomineePosition = (typeof NomineePositions)[number];

export const NominatorRoles = [
  "Coach",
  "Director",
  "Administrator/Teacher",
  "Family",
  "Friend/Fan",
] as const;
type NominatorRole = (typeof NominatorRoles)[number];

export interface NominationDocument<T = Timestamp> {
  nomineeFirstName: string;
  nomineeLastName: string;
  nomineeGrade: NomineeGrade;
  nomineePosition: NomineePosition;
  nomineeOtherSports?: string;
  nomineeAwards?: string;
  nomineePhoto?: string;
  gameDate: DateTime;
  gameAgainst: string;
  gameStats: string;
  gameComments: string;
  nomineeSchool: string;
  coachName: string;
  nomineeClassification: NomineeClassification;
  nominatorFirstName: string;
  nominatorLastName: string;
  nominatorEmail: string;
  submittedAt?: T;
  status: NominationStatus;
  id?: string;
}
export interface WinnerDocument<T = Timestamp> {
  athleteName: string;
  athleteSchool: string;
  athleteGrade: NomineeGrade;
  athleteClassification: NomineeClassification;
  athletePosition: string;
  athletePhoto?: string;
  athleteProfile?: string;
  nominatorRole?: NominatorRole;
  featuredDate?: T;
  status?: NominationStatus;
  nominationId?: string;
  winnerId?: string;
  id?: string;
}

export interface ConfigDocument<T = Timestamp> {
  currentMonth: T;
  currentWeek: number;
  currentSeason: number;
  publishedYear: number;
  activeSeasons: number[];
}
export interface YearWinnersDocument {
  athleteName: string;
  athleteClassification: NomineeClassification;
  athleteSchool: string;
  athleteGrade: NomineeGrade;
  athletePosition: string;
  athletePhoto?: string;
  id?: string;
}
