import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Routes";
import { Theme } from "Theme";
import { UseUserProvider } from "utils/useContext";

function App() {
  return (
    <Theme>
      <UseUserProvider>
        <Router>
          <Routes />
        </Router>
      </UseUserProvider>
    </Theme>
  );
}

export default App;
