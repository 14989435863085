import React, { useState } from "react";
import styled from "styled-components";
import {
  NominationDocument,
  NominationStatus,
  WinnerDocument,
  YearWinnersDocument,
} from "types/types";
import { truncateDetails } from "utils/useTruncate";
import { Checkbox } from "Components/RegistrationForm/Fields/Field/Checkbox";
import { H2, H3, Subtitle1, Body1, Label, Body2 } from "Components/Text";
import { Modal } from "Components/Modal";
import { ButtonPrimary } from "Components/Button";
import { AnimatePresence, motion } from "framer-motion";
import { Field } from "Components/RegistrationForm/Fields";
import { DateTime } from "luxon";
import {
  QuerySnapshot,
  Timestamp,
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { firebaseApp } from "Components/Firebase";
import { WinnerModal } from "Components/WinnerModal";
import { dropInList } from "Assets/Animate";
import { YearWinnerModal } from "Components/YearWinnerModal";

type ListItemProps = Partial<NominationDocument> &
  Partial<WinnerDocument> & {
    modalData?: NominationDocument;
    winnerData?: WinnerDocument;
    winnerYearData?: YearWinnersDocument;
    winnerView?: boolean;
    setSelectIds?: (v: string[]) => void;
    selectIds?: string[];
    docId?: string;
    winnerId?: string;
    docData?: QuerySnapshot<NominationDocument>;
    yearly?: boolean;
  };

export const ListItem = ({
  athleteName,
  athleteSchool,
  athleteClassification,
  athletePosition,
  athleteGrade,
  docId,
  winnerId,
  modalData,
  winnerData,
  winnerYearData,
  submittedAt,
  winnerView,
  setSelectIds,
  selectIds,
  docData,
  yearly,
}: ListItemProps) => {
  const db = getFirestore(firebaseApp);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWinnerModal, setIsOpenWinnerModal] = useState(false);
  const [isOpenYearWinnerModal, setIsOpenYearWinnerModal] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [status, setStatus] = useState<NominationStatus | null>(null);
  const [featuredDate, setFeaturedDate] = useState<string>("");
  const weekOptions = Array.from(Array(11).keys()).map((n) => n + 1);
  const dateOptions = weekOptions.map((w) => ({ id: w, title: w.toString() }));
  const currentSeason = DateTime.now().toFormat("yy");

  const selectAthletes = (id: string) => {
    let list = [...selectIds!] || [];
    if (list.includes(id)) {
      let update = list.filter((v) => {
        return v !== id;
      });
      setSelectIds && setSelectIds(update);
    } else {
      list.push(id);
      setSelectIds && setSelectIds(list);
    }
  };

  const updateStatusModal = async (
    snap: QuerySnapshot<NominationDocument> | undefined,
    status: NominationStatus,
    id: string,
  ) => {
    snap?.docs.map(async (nominationDoc) => {
      if (nominationDoc?.id === id) {
        if (status === "winner" && featuredDate && currentSeason) {
          const week =
            featuredDate.length < 2 ? `0${featuredDate}` : featuredDate;
          const winnerId =
            currentSeason +
            "-" +
            week +
            "-" +
            (nominationDoc.data().nomineeClassification === "Private"
              ? "PR"
              : nominationDoc.data().nomineeClassification);

          const newWinner = {
            athleteName:
              nominationDoc.data().nomineeLastName +
              " " +
              nominationDoc.data().nomineeFirstName,
            athleteSchool: nominationDoc.data().nomineeSchool,
            athleteGrade: nominationDoc.data().nomineeGrade,
            athleteClassification: nominationDoc.data().nomineeClassification,
            athletePosition: nominationDoc.data().nomineePosition,
            athletePhoto: nominationDoc.data().nomineePhoto || "",
            nominationId: nominationDoc.id,
            status: "winner",
          };
          await setDoc(doc(db, "winners", winnerId), { ...newWinner });
          await setDoc(
            nominationDoc.ref,
            {
              status: "winner",
            },
            { merge: true },
          );
        } else {
          await setDoc(
            nominationDoc.ref,
            {
              status: status,
            },
            { merge: true },
          );
        }
      }
    });
  };

  const statusOptions = {
    winner: "Winner",
    consideration: "In Consideration",
    archived: "Archived",
  };

  return (
    <>
      <ListWrapper key={docId}>
        <div>
          {!winnerView && (
            <Checkbox
              checked={checked}
              title={athleteName || ""}
              onChange={() => {
                setChecked(!checked);
                selectAthletes(docId || "");
              }}
            />
          )}
        </div>
        <div>
          {winnerView ? (
            <Subtitle1
              style={{
                color: "#1700F4",
                fontWeight: 600,
                cursor: "pointer",
                lineHeight: "120%",
                marginTop: "-2px",
              }}
              onClick={() => {
                setIsOpenWinnerModal(true);
              }}
            >
              {athleteName || ""}
            </Subtitle1>
          ) : (
            athleteName || ""
          )}
        </div>
        <div>
          {(athleteSchool || "")?.length > 25
            ? truncateDetails(athleteSchool || "", 25) + "..."
            : athleteSchool}
        </div>
        <div>{athleteClassification || ""}</div>
        <div>
          {" "}
          {(athletePosition || "")?.length > 20
            ? truncateDetails(athletePosition || "", 20) + "..."
            : athletePosition}
        </div>
        <div>
          {winnerView ? (
            <Body2 style={{ color: "#999" }}>N/A</Body2>
          ) : (
            <Subtitle1
              onClick={() => setIsOpen(true)}
              style={{
                color: "#1700F4",
                fontWeight: 700,
                cursor: "pointer",
                lineHeight: "130%",
              }}
            >
              View
            </Subtitle1>
          )}
        </div>
        {submittedAt ? (
          <div>{submittedAt?.toDate().toLocaleDateString()}</div>
        ) : (
          <div>{athleteGrade}</div>
        )}
      </ListWrapper>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} key={docId + "-"}>
        <H2 style={{ color: "#112B4D" }}>Nomination</H2>
        <Wrapper>
          <H3>Athlete’s Information</H3>
          <Label disabled>Nominee First Name</Label>
          <Subtitle1>{modalData?.nomineeFirstName}</Subtitle1>
          <Label disabled>Nominee Last Name</Label>
          <Subtitle1>{modalData?.nomineeLastName}</Subtitle1>
          {modalData?.nomineePhoto && (
            <>
              <Label disabled>Photo</Label>
              <AthleteImg src={modalData?.nomineePhoto} />
            </>
          )}
          <Label disabled>Nominee School</Label>
          <Subtitle1>{modalData?.nomineeSchool}</Subtitle1>
          <Label disabled>Nominee Classification</Label>
          <Subtitle1>class {modalData?.nomineeClassification}</Subtitle1>
          <Label disabled>Nominee Position</Label>
          <Subtitle1>{modalData?.nomineePosition}</Subtitle1>
          <Label disabled>Nominee Coach</Label>
          <Subtitle1>{modalData?.coachName}</Subtitle1>
          <H3>Game Information</H3>
          {modalData?.gameStats && (
            <>
              <Label disabled>Game Stats</Label>
              <Subtitle1>{modalData?.gameStats}</Subtitle1>
            </>
          )}
          <Label disabled>Against</Label>
          <Subtitle1>{modalData?.gameAgainst}</Subtitle1>
          <Label disabled>Game Comments</Label>
          <Subtitle1>{modalData?.gameComments}</Subtitle1>
          <Label disabled>Game Date</Label>
          <Subtitle1>
            {DateTime.fromMillis(
              (modalData?.gameDate || DateTime.now())?.toMillis(),
            ).toFormat("dd LLL yyyy")}
          </Subtitle1>
          <H3>Nominator Information</H3>
          <Label disabled>Nominator First Name</Label>
          <Subtitle1>{modalData?.nominatorFirstName}</Subtitle1>
          <Label disabled>Nominator Last Name</Label>
          <Subtitle1>{modalData?.nominatorLastName}</Subtitle1>
          <Label disabled>Nominator Email</Label>
          <Subtitle1>{modalData?.nominatorEmail}</Subtitle1>
          <Label disabled>Select Status</Label>
          <Field
            type="select"
            onChange={(value: NominationStatus) => {
              const [selectValue] =
                Object.entries(statusOptions).find(
                  ([key, val]) => val === value,
                ) || [];

              setStatus(selectValue as typeof status);
            }}
            options={[
              { id: 1, title: "Winner" },
              {
                id: 2,
                title: "In Consideration",
              },
              { id: 3, title: "Archived" },
            ]}
            placeholder="Select"
            value={
              statusOptions[
                (status || modalData?.status) as keyof typeof statusOptions
              ]
            }
          />
          {status === "winner" && (
            <>
              <Label>Featured in (Week)</Label>
              <Field
                type="select"
                onChange={(value: string) => setFeaturedDate(value)}
                options={dateOptions}
                placeholder="Select"
                value={featuredDate}
              />
              <Label disabled>Featured in (Season)</Label>
              <Field
                disabled
                type="text"
                onChange={(value: string) => setFeaturedDate(value)}
                placeholder="Select"
                value={`20${currentSeason}`}
              />
            </>
          )}
          <ButtonPrimary
            style={{ marginTop: 30 }}
            onClick={() => {
              if (status) {
                updateStatusModal(docData, status, docId || "");
              }
              setIsOpen(false);
            }}
            disabled={
              !status ? true : status === "winner" ? !featuredDate : false
            }
          >
            Save Status
          </ButtonPrimary>
          <ButtonPrimary
            text={true}
            onClick={() => {
              setStatus(null);
              setFeaturedDate("");
              setIsOpen(false);
            }}
          >
            Close
          </ButtonPrimary>
        </Wrapper>
      </Modal>
      {isOpenWinnerModal && (
        <WinnerModal
          setIsOpen={setIsOpenWinnerModal}
          isOpen={isOpenWinnerModal}
          winnerData={winnerData}
          yearly={yearly}
          winnerId={docId}
        />
      )}
      {/* {isOpenYearWinnerModal && (
        <YearWinnerModal
          setIsOpen={setIsOpenWinnerModal}
          isOpen={isOpenWinnerModal}
          winnerData={winnerYearData}
        />
      )} */}
    </>
  );
};
const ListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1.1fr 1.3fr 0.8fr 1fr 1fr 1fr;
  padding: 8px 0;
  overflow: hidden !important;
  & > div {
    font-size: 14px;
    font-weight: 500;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    & > div {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;
export const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #112b4d;
  button {
    align-self: center;
  }
  ${Label} {
    margin-top: 20px;
    margin-bottom: 2px;
  }
  ${H3} {
    margin-top: 30px;
    color: #112b4d;
  }
  & > div {
    width: 100%;
  }
`;
const AthleteImg = styled.img`
  width: 200px !important;
  object-fit: cover !important;
  margin: 0px auto 10px 0 !important;
`;
