import styled, { css } from "styled-components";

type TextProps = {
  disabled?: boolean;
};

export const H1 = styled.h1`
  font-size: 40px;
  font-weight: 600;
  line-height: 160%;
  font-family: "F1Bold";
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 32px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 25px;
  }
`;

export const Metric = styled.h1`
  font-size: 38px;
  font-weight: 700;
  line-height: 54px;
  font-family: "F1Bold";
`;

export const H2 = styled.h2`
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  font-family: "F1Bold";
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 27px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 22px;
  }
`;

export const H3 = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  font-family: "F1Bold";
`;

export const H4 = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: "F1Bold";
`;

export const Subtitle1 = styled.h4`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "F1Med";
`;

export const Subtitle2 = styled.h4`
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  font-family: "F1Med";
`;

export const Caption = styled.p`
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`;

export const UserText = styled.p<TextProps>`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

export const Selected = styled.p<TextProps>`
  font-size: 15;
  font-weight: 600;
  line-height: 22px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

export const LinkText = styled.p`
  font-size: 16;
  font-weight: 600;
  line-height: 24px;
`;

export const Li = styled.p<TextProps>`
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

export const Body1 = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  font-family: "F1Reg";
`;

export const Body2 = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: "F1Reg";
`;

export const Td = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const Label = styled.label<TextProps>`
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "F1Reg";
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

export const Error = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #e45c52;
`;
