import _ from "lodash";

export const truncateDetails = (str: string, length?: number) => {
  const truncate = _.truncate;
  return truncate(str, {
    length: length || 250,
    separator: " ",
    omission: " ",
  });
};
