import React from "react";
import styled from "styled-components";

export const ProgressRing = ({
  progress = 0,
  size = "large",
  color = "purple",
  ...props
}) => {
  const stroke = 6;
  const radius = size !== "small" ? 50 : 12;
  const normalizedRadius = radius * 1.1;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Wrapper
      //style={{ width: `${radius * 2 - 8}`, height: `${radius * 2 - 8}` }}
      {...props}
    >
      <Container style={{ width: `${radius * 2}`, height: `${radius * 2}` }}>
        {size !== "small" && (
          <Percentage color={color}>
            {Math.floor(progress)}
            <Sign>%</Sign>
          </Percentage>
        )}
        <svg
          height={radius * 3}
          width={radius * 3}
          viewBox={`-10 -10 ${radius * 2 + 20} ${radius * 2 + 20}`}
        >
          <SolidCircle
            id={`solidCircle${size}`}
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            color={color}
          />

          <Circle
            id={`baseCircle${size}`}
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            circumference={circumference}
            offset={strokeDashoffset}
            color={color}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.5665 0.956543C51.6556 0.956543 53.3491 2.65007 53.3491 4.73915V50.1305C53.3491 52.2195 51.6556 53.9131 49.5665 53.9131H4.17519C2.08611 53.9131 0.392578 52.2195 0.392578 50.1305V4.73915C0.392578 2.65007 2.08611 0.956543 4.17519 0.956543H49.5665ZM49.5662 42.5653H4.17485V50.1305H49.5662V42.5653ZM49.5662 4.73924H4.17485V38.7827H49.5662V4.73924ZM34.4354 12.3044L45.7832 35.0001H7.95712L19.3049 19.8696L26.8702 23.6522L34.4354 12.3044ZM33.9681 19.8261L28.1662 28.5289L20.4379 24.6647L15.5234 31.2175H39.6638L33.9681 19.8261ZM11.7397 8.52172C13.8288 8.52172 15.5223 10.2153 15.5223 12.3043C15.5223 14.3934 13.8288 16.0869 11.7397 16.0869C9.65065 16.0869 7.95712 14.3934 7.95712 12.3043C7.95712 10.2153 9.65065 8.52172 11.7397 8.52172Z"
            fill="#666"
            style={{ margin: 10 }}
          />
        </svg>
      </Container>
    </Wrapper>
  );
};

const Percentage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #666;
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 180px;
  margin-left: 10px;
`;

const Sign = styled.div`
  font-size: 13px;
  font-weight: 400;
  margin-left: 1px;
`;

const Wrapper = styled.div`
  display: block;
  width: 200px;
  height: 200px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  object-position: 50% 50%;
  width: 200px;
  height: 200px;
  position: relative;
`;

const Circle = styled.circle<{ circumference: number }>`
  stroke-dasharray: ${(props) =>
    `${props.circumference} ${props.circumference}`};
  stroke-dashoffset: ${(props) => props.offset};
  transition: stroke-dashoffset 0.35s;
  transform: translateY(-20px) rotate(-90deg);
  transform-origin: center;
  stroke: #7575da;
`;

const SolidCircle = styled.circle`
  stroke: #c2c2c2;
  transform-origin: center;
`;
const Path = styled.path`
  transform: translate(23px, 22px);
`;
